<div class="container" *ngIf="isLoading">
    <app-loading></app-loading>
</div>

<form *ngIf="!isLoading" [formGroup]="requestForm" (ngSubmit)="onSubmit()">
    <div class="logo-container">
        <img *ngIf="housingPartner && housingPartner.logoURL" src={{housingPartner.logoURL}} alt={{housingPartner.partnerName}} >
    </div>
    <mat-card>
        <div class="housing-contact-form-mat-card">
            <h1 class="center-title-join" *ngIf="housingPartner">{{housingPartner.partnerName}}
            </h1>
            <p class="center-paragraph">Complete the fields below and submit your request to our
                partner. They will reach out to you directly to help address your
                housing needs.</p>

                <h2 *ngIf="isMessageSent == true" class="message-sent-header">Your message has been sent. Thank you!</h2>
            <mat-card-content>
                <div fxLayout="row" fxLayoutGap="10px" fxLayoutGap.lt-sm="5px">
                    
                    <mat-form-field appearance="outline" fxFlex="100">
                        <mat-label>Select Partner</mat-label>
                        <mat-select formControlName="partnerId" required>
                            <mat-option *ngFor="let partner of partners" [value]="partner.id">
                                {{ partner.partnerName }}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="requestForm.get('partnerId').hasError('required')">
                            Partner selection is required
                        </mat-error>
                    </mat-form-field>
                </div>
                <div fxLayout="row" fxLayoutGap="10px" fxLayoutGap.lt-sm="5px">
                    <span *ngIf="isPersonalInfoLoading" class="loading">Loading your data</span>
                </div>
                <div fxLayout="row" fxLayoutGap="10px" fxLayoutGap.lt-sm="5px">

                    <!-- Contact Information -->
                    <div formGroupName="contactInfo" fxFlex="100" fxFlex.lt-sm="100">
                        <mat-card-title>Contact Information</mat-card-title>
                        <div fxLayout="row" fxLayoutGap="10px" fxLayoutGap.lt-sm="5px">

                        <mat-form-field appearance="outline"  fxFlex="50" >
                            <mat-label>Contact First Name</mat-label>
                            <input matInput placeholder="First Name*" formControlName="firstName" required>
                            <mat-error *ngIf="requestForm.get('contactInfo').get('firstName').hasError('required')">
                                First name is required
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="outline"  fxFlex="50"  >
                            <mat-label>Contact Last Name</mat-label>
                            <input matInput placeholder="Last Name*" formControlName="lastName" required>
                            <mat-error *ngIf="requestForm.get('contactInfo').get('lastName').hasError('required')">
                                Last name is required
                            </mat-error>
                        </mat-form-field>
                        </div>
                        <div fxLayout="row" fxLayoutGap="10px" fxLayoutGap.lt-sm="5px">

                        <mat-form-field appearance="outline"  fxFlex="50" >
                            <mat-label>Email</mat-label>
                            <input matInput placeholder="Email*" formControlName="email" required>
                            <mat-error *ngIf="requestForm.get('contactInfo').get('email').hasError('required')">
                                Email is required
                            </mat-error>
                            <mat-error *ngIf="requestForm.get('contactInfo').get('email').hasError('email')">
                                Please enter a valid email
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="outline"  fxFlex="50" >
                            <mat-label>Phone Number</mat-label>
                            <input matInput placeholder="Phone Number" formControlName="phone" >
                            <mat-error *ngIf="requestForm.get('contactInfo').get('phone').hasError('required')">
                                Phone number is required
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>
                </div>
                <div fxLayout="row" fxLayoutGap="10px" fxLayoutGap.lt-sm="5px">
                    <!-- Property Information -->
                    <div formGroupName="propertyInfo" fxFlex="100" fxFlex.lt-sm="100">
                        <mat-card-title>Property Information</mat-card-title>
                    
                        <div fxLayout="row" fxLayoutGap="10px" fxLayoutGap.lt-sm="5px">
                        <mat-form-field appearance="outline"  fxFlex="50" >
                            <mat-label>Property Name</mat-label>
                            <input matInput placeholder="Property Name*" formControlName="propertyName" required>
                            <mat-error *ngIf="requestForm.get('propertyInfo').get('propertyName').hasError('required')">
                                Property name is required
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="outline"  fxFlex="50" >
                            <mat-label>Address</mat-label>
                            <input matInput placeholder="Address*" formControlName="address" required>
                            <mat-error *ngIf="requestForm.get('propertyInfo').get('address').hasError('required')">
                                Address is required
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div fxLayout="row" fxLayoutGap="10px" fxLayoutGap.lt-sm="5px">
                        <mat-form-field appearance="outline"  fxFlex="50" >
                            <mat-label>City</mat-label>
                            <input matInput placeholder="City*" formControlName="city" required>
                            <mat-error *ngIf="requestForm.get('propertyInfo').get('city').hasError('required')">
                                City is required
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="outline"  fxFlex="50" >
                            <mat-label>State</mat-label>
                            <input matInput placeholder="State*" formControlName="state" required>
                            <mat-error *ngIf="requestForm.get('propertyInfo').get('state').hasError('required')">
                                State is required
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div fxLayout="row" fxLayoutGap="10px" fxLayoutGap.lt-sm="5px">
                        <mat-form-field appearance="outline"  fxFlex="100" >
                            <mat-label>ZIP</mat-label>
                            <input matInput placeholder="ZIP*" formControlName="zip" required>
                            <mat-error *ngIf="requestForm.get('propertyInfo').get('zip').hasError('required')">
                                ZIP is required
                            </mat-error>
                            <mat-error *ngIf="requestForm.get('propertyInfo').get('zip').hasError('pattern')">
                                ZIP is not valid
                            </mat-error>
                        </mat-form-field>
                    </div>
                    </div>
                </div>


                <!-- Housing Needs -->
                <div formGroupName="housingNeeds">
                    <mat-card-title>Housing Needs</mat-card-title>

                    <div fxLayout="row" fxLayoutGap="10px" fxLayoutGap.lt-sm="5px">

                        <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-sm="100">
                            <mat-label>Start Date</mat-label>
                            <input matInput [matDatepicker]="startDatePicker" placeholder="Start Date"
                                formControlName="start"   [min]="minDate">
                            <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
                            <mat-datepicker #startDatePicker></mat-datepicker>
                            <mat-error *ngIf="requestForm.get('housingNeeds').get('start').hasError('required')">
                                Start date is required
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-sm="100">
                            <mat-label>End Date</mat-label>
                            <input matInput [matDatepicker]="endDatePicker" placeholder="End Date"
                                formControlName="end"   [min]="minDate">
                            <mat-datepicker-toggle matSuffix [for]="endDatePicker"></mat-datepicker-toggle>
                            <mat-datepicker #endDatePicker></mat-datepicker>
                            <mat-error *ngIf="requestForm.get('housingNeeds').get('end').hasError('required')">
                                End date is required
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div fxLayout="row" fxLayoutGap="10px" fxLayoutGap.lt-sm="5px">

                        <mat-form-field appearance="outline" fxFlex="100">
                            <mat-label>Number of Employees Needing Lodging</mat-label>
                            <input matInput type="number" placeholder="# of Employees*" formControlName="employees"
                                min="0" >
                            <mat-error *ngIf="requestForm.get('housingNeeds').get('employees').hasError('required')">
                                This field is required
                            </mat-error>
                            <mat-error *ngIf="requestForm.get('housingNeeds').get('employees').hasError('min')">
                                At least one employee is required
                            </mat-error>
                        </mat-form-field>

                    </div>
                    <div fxLayout="row" fxLayoutGap="10px" fxLayoutGap.lt-sm="5px">

                        <mat-form-field appearance="outline" fxFlex="50">
                            <mat-label>Budget per person</mat-label>
                            <input matInput placeholder="$" formControlName="budget"
                                min="0" >
                           
                        </mat-form-field>
                        
                        <mat-form-field appearance="outline"  fxFlex="50" >
                            <mat-label>Current housing provider(if any)</mat-label>
                            <input matInput placeholder="Provider" formControlName="provider" >

                        </mat-form-field>

                    </div>
                    <mat-form-field appearance="outline" class="full-width">
                        <mat-label>Describe what you're looking for</mat-label>
                        <textarea matInput placeholder="Description*" formControlName="description" required></textarea>
                        <mat-error *ngIf="requestForm.get('housingNeeds').get('description').hasError('required')">
                            Description is required
                        </mat-error>
                    </mat-form-field>
                </div>
            </mat-card-content>

            <mat-card-actions>
                <button mat-button type="button" (click)="onCancel()">Cancel</button>
                <button mat-raised-button color="primary" type="submit" class="submit-button" [disabled]="isMessageSent">Submit</button>

            </mat-card-actions>
        </div>
    </mat-card>
</form>