import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { AppInsightsLogginService } from './logging.service';

@Injectable()
export class ErrorHandlerService extends ErrorHandler {
    constructor(private injector: Injector) {
        super();
    }
    override handleError(error: Error) {
        console.error(error);
        const appInsightsLoggingService= this.injector.get(AppInsightsLogginService);
        appInsightsLoggingService.logException(error);
    }
}