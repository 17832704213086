import { Component } from '@angular/core';
import { AuthService, User } from '@auth0/auth0-angular';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '../../api.service';
import { SharedDataService } from 'src/app/shared-data-service.service';
import { EMPTY, Observable, Subject, catchError, filter, from, map, startWith, switchMap, take, takeUntil } from 'rxjs';
import { Customer, Invoice, Package } from 'src/app/models/ChargeOverModels';
import { ErrorModalComponent } from 'src/app/error-modal/error-modal.component';
import { InfoModalComponent } from 'src/app/info-modal/info-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { EmployerContactSubscriptionDTO } from 'src/app/models/AccountManagementModels';
import { trigger, transition, useAnimation } from '@angular/animations';
import { bounceIn, fadeIn } from 'ng-animate';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { ConfirmationCodeDialogComponent } from 'src/app/confirmation-code-dialog/confirmation-code-dialog.component';
//import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-cancel-subscription',
  templateUrl: './cancel-subscription.component.html',
  styleUrls: ['./cancel-subscription.component.css'],
  animations: [trigger('bounceIn', [transition('* => *', useAnimation(bounceIn))]), trigger('fadeIn', [transition('* => *', useAnimation(fadeIn))])],

})
export class CancelSubscriptionComponent {
  unsubscribe$ = new Subject<void>();
  customer: Customer;
  package: Package;
  invoices: Invoice;
  employerContactSubscription: EmployerContactSubscriptionDTO;
  user: User;
  userType: string;
  portalURL: string;
  displayedColumns: string[] = ['invoice_id', 'date', 'due_date', 'invoice_status_name', 'total', 'payments', 'balance', 'actions'];
  isSmallScreen: boolean = false;
  bounceIn: any;
  fadeIn: any;
  faDownload = faDownload;
  employerContactId: number;
  constructor(public auth: AuthService,
    private sharedDataService: SharedDataService,
    private apiService: ApiService,
    private route: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
    ) { }

  ngOnInit() {

    this.auth.user$
      .pipe(
        // Remove null or undefined values
        filter(user => !!user && !!user.nickname),
        // Get the primary employer for the contact
        switchMap(user => {
          const arrMeta = user.nickname.split(':');
          this.employerContactId = Number.parseInt(arrMeta[1]);
          this.userType = arrMeta[0];
          this.portalURL = this.sharedDataService.getData('portalURL');
          if (this.userType === 'E') {
            return this.apiService.getPrimaryEmployerForContact(this.employerContactId);
          }
          
          return EMPTY;
        }),

        // Handle errors in the pipeline
        catchError(e => {
          console.error(e);
          this.openErrorModal(e);
          this.router.navigate(['/error']);
          return EMPTY;
        }),

        // Unsubscribe when component is destroyed
        takeUntil(this.unsubscribe$)
      ).subscribe(employerContactSubscription => {
        this.employerContactSubscription = employerContactSubscription;
        const acceptTerms = this.employerContactSubscription.employer.acceptTerms;
        const acceptSubscription = this.employerContactSubscription.employer.acceptSubscription;
        // Check if either date is null or older than the SC open date
        if ((acceptTerms === null || new Date(acceptTerms) < new Date('2021-01-01')) ||
          (acceptSubscription === null || new Date(acceptSubscription) < new Date('2021-01-01'))) {
          this.router.navigate(['/accept-terms']);
          return;
        }
        this.apiService.getChargeoverSubscription(this.employerContactSubscription.employer.employerId)
        .pipe(
          catchError(e => {
            console.error(e);
            this.openErrorModal(e);
            //this.router.navigate(['/error']);
            return EMPTY;
          }),
          takeUntil(this.unsubscribe$)
        )
        .subscribe(data => {
          this.package = data;

        });
        
      });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  openErrorModal(error: any): void {
    const dialogRef = this.dialog.open(ErrorModalComponent, {
      data: error,
      maxWidth: '70vw', // specify max width
      maxHeight: '80vh', // specify max height
      //height: '80vh' // specify height
    });   

    // Optionally, you can subscribe to the afterClosed observable to perform actions after the modal is closed.
    dialogRef.afterClosed().subscribe(() => {
      // Perform any actions after the modal is closed (if needed).
    });
  }
  openInfoModal(message: string): void {
    const dialogRef = this.dialog.open(InfoModalComponent, {
      data: { message: message },
      maxWidth: '70vw', 
      maxHeight: '80vh',
    });   
  
    dialogRef.afterClosed().subscribe(() => {
      this.router.navigate(['/']);
    });
  }

  openConfirmationDialog(): void {
    const dialogRef = this.dialog.open(ConfirmationCodeDialogComponent, {
      data: {
        message: 'Are you sure you want to cancel?'
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.apiService.CancelChargeoverSubscription(this.employerContactSubscription.employer.employerId, this.employerContactId).pipe(
          switchMap(data => {
            //const formattedDate = this.datePipe.transform(this.package?.next_invoice_datetime, 'MM/dd/yyyy');

            this.openInfoModal("Your subscription has been canceled and it will last until end of this billing cycle" /*+ formattedDate*/)
            return EMPTY;
          }),
          catchError(e => {
            console.error(e);
            this.openErrorModal(e);
            this.router.navigate(['/']);
            return EMPTY;
          }),
          takeUntil(this.unsubscribe$)
        )
        .subscribe();
      }
          
    })
        // Display your message that code has been sent

  }
}