import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

export interface Country {
  CountryCode: string;
  CountryName: string;
}
@Injectable({
  providedIn: 'root'
})
export class CountryService {

  private jsonUrl = '../assets/CountriesWithCode.json';

  constructor(private http: HttpClient) {}

  public getAllCountries(): Observable<Country[]> {
    return this.http.get<Country[]>(this.jsonUrl);
  }
}
