<mat-dialog-content class="modal-container">
  <div class="modal-header">
    <button mat-icon-button class="close-button" (click)="closeModal()">
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <div class="modal-body">   
    <h3 *ngIf="!title">Info</h3>
    <h3 *ngIf="title">{{ title }}</h3>

    <p *ngIf="message">{{ message }}</p>
    
  </div>

  <div class="modal-footer">
    <button mat-raised-button color="primary" (click)="closeModal()">Close</button> 
    <a class="open-button" *ngIf="url" [href]="url" mat-raised-button color="accent" target="_blank" rel="noopener noreferrer">Open</a>
  </div>
</mat-dialog-content>