import { Component, OnInit, OnDestroy } from '@angular/core';
import { AuthService, User } from '@auth0/auth0-angular';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '../../api.service';
import { SharedDataService } from '../../shared-data-service.service';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { CreateEmployerModel, CreditCard, Employer, PaymentDetails, PlanPriceResponse } from '../../models/SignupModels';
import { EMPTY, Observable, Subject, catchError, filter, from, map, startWith, switchMap, take, takeUntil } from 'rxjs';
import { FormControl } from '@angular/forms';
import { ChargeOverService } from '../../chargeover.service';
import { MatDialog } from '@angular/material/dialog';
import { ErrorModalComponent } from '../../error-modal/error-modal.component';
import { ConfirmationCodeDialogComponent } from '../../confirmation-code-dialog/confirmation-code-dialog.component';
import { EmployerContactSubscriptionDTO, ChangePlanRequest } from 'src/app/models/AccountManagementModels';
import { Package } from 'src/app/models/ChargeOverModels';
import { InfoModalComponent } from 'src/app/info-modal/info-modal.component';



@Component({
  selector: 'app-buy-subscription',
  templateUrl: './buy-subscription.component.html',
  styleUrls: ['./buy-subscription.component.css']
})
export class BuySubscriptionComponent {

  unsubscribe$ = new Subject<void>();
  plans = [
    { value: 'Basic', viewValue: 'Basic' },
    { value: 'Advanced', viewValue: 'Advanced' },
    { value: 'Premium', viewValue: 'Premium' },
  ];
  employerContactSubscription: EmployerContactSubscriptionDTO;
  amountToPay = 99.99;
  paymentForm: FormGroup;
  isLoading = true;
  isPriceLoading: boolean = false;
  planPriceResponse: PlanPriceResponse;
  plan: string = 'Basic';
  yearly: boolean = false;
  employerContactId: number;
  userType: string;
  portalURL: string;
  package: Package;

  constructor(
    public auth: AuthService,
    private sharedDataService: SharedDataService,
    private apiService: ApiService,
    private route: ActivatedRoute,
    private chargeOverService: ChargeOverService,
    private fb: FormBuilder,
    private router: Router,
    private dialog: MatDialog
  ) {

    this.paymentForm = this.fb.group({
      couponCode: [''],
      yearly: [false],
      plan: ['Basic', Validators.required],
    }, { validators: [] });


  }

  ngOnInit(): void {
    this.isLoading = true;
    
    this.auth.user$
      .pipe(
        // Remove null or undefined values
        filter(user => !!user && !!user.nickname),
        // Get the primary employer for the contact
        switchMap(user => {
          const arrMeta = user.nickname.split(':');
          this.employerContactId = Number.parseInt(arrMeta[1]);
          this.userType = arrMeta[0];          
          this.portalURL = this.sharedDataService.getData('portalURL');
          if (this.userType === 'E') {
            return this.apiService.getPrimaryEmployerForContact(this.employerContactId);
          }

          return EMPTY;
        }),

        // Handle errors in the pipeline
        catchError(e => {
          console.error(e);
          this.openErrorModal(e);
          this.router.navigate(['/error']);
          return EMPTY;
        }),

        // Unsubscribe when component is destroyed
        takeUntil(this.unsubscribe$)
      ).subscribe(employerContactSubscription => {
        this.employerContactSubscription = employerContactSubscription;
        const acceptTerms = this.employerContactSubscription.employer.acceptTerms;
        const acceptSubscription = this.employerContactSubscription.employer.acceptSubscription;
        // Check if either date is null or older than the SC open date
        if ((acceptTerms === null || new Date(acceptTerms) < new Date('2021-01-01')) ||
          (acceptSubscription === null || new Date(acceptSubscription) < new Date('2021-01-01'))) {
          this.router.navigate(['/accept-terms']);
          return;
        }
        this.apiService.getChargeoverSubscription(this.employerContactSubscription.employer.employerId)
          .pipe(
            catchError(e => {
              console.error(e);
              if(e.error.errorCode === 2)
              {
                this.calculatePrice();
                this.setInitialData();
                this.isLoading = false; 
                return EMPTY;
              }
              this.openErrorModal(e);
              this.isLoading = false;
              //this.router.navigate(['/error']);
              return EMPTY;
            }),
            takeUntil(this.unsubscribe$)
          )
          .subscribe(data => {
            this.package = data;
            if(this.package)
            {
              if(this.package.package_status_str == 'active-current')
              {
                this.router.navigate(['change-subscription']);
              }

              const arrMeta = this.package.line_items[0].item_name.split(' ');
              this.plan = arrMeta[0];
              if (arrMeta[1]) 
              {
                this.yearly = true;
              }
            }
            this.calculatePrice();
            this.setInitialData();
            this.isLoading = false;  // Reset the loading flag
          });

      });

  }
  onSubmit() {
    this.updatePlanName();


    this.isLoading = true;

    const changePlanRequest: ChangePlanRequest = {
      planName: this.plan,
      couponCode: this.paymentForm.get('couponCode').value,
    }

    this.apiService.BuyChargeoverSubscription(
      this.employerContactSubscription.employer.employerId,
      this.employerContactId,
      changePlanRequest)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: (result) => {
          this.router.navigate(['/']);
        },
        error: (e) => {
          this.isLoading = false;
          this.openErrorModal(e);
        }
      });
  }

  updatePlanName() {
    this.plan = this.plan.toLowerCase();
    if (this.yearly && !this.plan.includes('yearly')) {
      this.plan += '-yearly';
    }
  }

  calculatePrice() {
    this.isPriceLoading = true;
    this.apiService.getPlanPrice(this.plan, this.yearly || false, this.paymentForm.get('couponCode').value)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((data: any) => {
        this.planPriceResponse = data as PlanPriceResponse
        this.amountToPay = this.planPriceResponse.price;
        this.isPriceLoading = false;
        if (!this.planPriceResponse.isSuccess) {
          this.openErrorModal(this.planPriceResponse.message);
        }
      });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
  openErrorModal(error: any): void {
    const dialogRef = this.dialog.open(ErrorModalComponent, {
      data: error,
      maxWidth: '70vw', // specify max width
      maxHeight: '80vh', // specify max height
      //height: '80vh' // specify height
    });

    // Optionally, you can subscribe to the afterClosed observable to perform actions after the modal is closed.
    dialogRef.afterClosed().subscribe(() => {
      // Perform any actions after the modal is closed (if needed).
    });
  }


  setInitialData() {
    this.paymentForm.get('plan').setValue(this.plan);

    this.paymentForm.get('yearly').setValue(this.yearly);

  }

  yearlyToggleTriggered() {
    this.yearly = this.paymentForm.get('yearly').value;
    this.calculatePrice();
  }
  planChangeTrigered() {
    this.plan = this.paymentForm.get('plan').value;
    this.calculatePrice();
  }

  openInfoModal(message: string): void {
    const dialogRef = this.dialog.open(InfoModalComponent, {
      data: { message: message },
      maxWidth: '70vw', 
      maxHeight: '80vh',
    });   
  
    dialogRef.afterClosed().subscribe(() => {
      this.router.navigate(['/']);
    });
  }

  openConfirmationDialog(): void {
    if (!this.paymentForm.valid) {
      this.paymentForm.markAllAsTouched();
      console.error('Form is not valid');
      return;
    }
    const dialogRef = this.dialog.open(ConfirmationCodeDialogComponent, {
      data: {
        message: 'Are you sure you want to buy subscription?'
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.onSubmit();
      }
          
    })
        // Display your message that code has been sent

  }

}

