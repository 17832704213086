import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import config from '../auth_config.json';
import { ACH, CreateEmployerModel, CreditCard } from './models/SignupModels';

@Injectable({
  providedIn: 'root'
})
export class ChargeOverService {
  private renderer: Renderer2;
  private chargeOverSetup = {
    instance: config.chargeover.instance,
    token: config.chargeover.token
  };

  constructor(private rendererFactory: RendererFactory2) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }
  private setupChargeOver(): void {
    const chargeOver = (window as any).ChargeOver;
    if (chargeOver) {
      chargeOver.Core.setup(this.chargeOverSetup);
    } else {
      console.error('ChargeOver not loaded properly.');
    }
  }
  loadChargeOver(): Promise<void> {
    return new Promise((resolve, reject) => {
      const script = this.renderer.createElement('script');
      script.type = 'text/javascript';
      script.src = config.chargeover.ScriptURL;
      script.onload = () => {
        this.setupChargeOver();
        resolve();
      };
      script.onerror = () => {
        reject();
      };
  
      this.renderer.appendChild(document.body, script);
    });
  }
  
  async signupCreditCardCall(employerData: CreateEmployerModel, creditCard: CreditCard, couponCode: string): Promise<any> {
    try {
      await this.loadChargeOver();
      const chargeOver = (window as any).ChargeOver;
      if (chargeOver) {
        return new Promise((resolve, reject) => {
          chargeOver.Signup.signup(
            this.generateCCModel(employerData, creditCard as CreditCard, couponCode),
            (code, message, response) => {
              //this.my_callback_function(code, message, response); // You can still use the existing callback if needed.
              if (code === chargeOver.Core.CODE_OK) {
                resolve(response);
              } else {
                console.log('reject',message);
                reject(message);
              }
            }
          );
        });
      } else {
        console.error('ChargeOver not loaded properly.');
        throw new Error('ChargeOver not loaded properly.');
      }
    } catch (e) {
      console.error('Failed to load ChargeOver.', e);
      throw e;
    }
  }
  

  my_callback_function(code, message, response)
  {
      const chargeOver = (window as any).ChargeOver;
      if (code == chargeOver.Core.CODE_OK)
      {
          alert('You have signed up! Thanks ' + response.user.first_name + '!');
      }
      else
      {
        console.log('An error occurred: ' + response);
          alert('An error occurred: ' + message);
      }
  }


  generateCCModel(employer: CreateEmployerModel, cc: any, couponCode: string): Object {
    let creditCardModel = {

      'send_welcome_notice': true,    // Default is FALSE. TRUE sends a welcome e-mail to new sign-ups, while FALSE will not send them anything.
      'send_payment_receipt': true,   // Default is FALSE. TRUE sends a payment receipt e-mail to new sign-ups, while FALSE will not send them anything.

      'customer': {
        company: employer.companyName,
        external_key: employer.employerId,
        bill_addr1: employer.street,
        bill_addr2: '',
        bill_addr3: '',
        bill_city: employer.city,
        bill_state: employer.state,
        bill_postcode: employer.zip,
        bill_country: 'United States',
      },
      'user': {
        name: employer.firstName + ' ' + employer.lastName,
        email: employer.email,
        //phone: '888-924-2347' TODO
      },
      'coupon': couponCode,
      'creditcard': cc,

        // Optional address information (can be used for address verification)
        // address: '',
        // city: '',
        // state: '',
        // postcode: '',
        // country: ''
      'item_external_keys': [
        employer.plan.toLowerCase()           // This is the item_id value of the product you want them signed up for TODO
      ]
    };
    return creditCardModel;
  }

  generateACHModel(employer: CreateEmployerModel, ach: ACH): Object {
    let achModel = {

      'send_welcome_notice': true,    // Default is FALSE. TRUE sends a welcome e-mail to new sign-ups, while FALSE will not send them anything.
      'send_payment_receipt': true,   // Default is FALSE. TRUE sends a payment receipt e-mail to new sign-ups, while FALSE will not send them anything.

      'customer': {
        company: employer.companyName,

        bill_addr1: employer.street,
        bill_addr2: '',
        bill_addr3: '',
        bill_city: employer.city,
        bill_state: employer.state,
        bill_postcode: employer.zip,
        bill_country: 'United States',
      },
      'user': {
        name: employer.firstName + ' ' + employer.lastName,
        email: employer.email,
        //phone: '888-924-2347'
      },
      'ach': {
        number: ach.accountNumber,
        routing: ach.routing,
        name: ach.name,

      },
      'item_external_keys': [
        employer.plan.toLowerCase()            // This is the item_id value of the product you want them signed up for TODO
      ]
    };

    return achModel;
  }

}
