<mat-dialog-content class="modal-container">
    <div class="modal-header">
      <button mat-icon-button class="close-button" (click)="closeModal()">
        <mat-icon>close</mat-icon>
      </button>
    </div>

<div class="modal-body">   
    <h3>Error</h3>
    <p>There has been an error. Please email us at <a href="mailto:info@seasonalconnect.com">info@seasonalconnect.com</a> for assistance.</p>
    <p *ngIf="errorMessage">Error details:</p>
    <p *ngIf="errorMessage">{{ errorMessage }}</p>
    
</div>

<div class="modal-footer">
  <button mat-raised-button color="primary" (click)="closeModal()">Close</button>
</div>
</mat-dialog-content>