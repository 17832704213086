import { Component, OnInit, OnDestroy } from '@angular/core';
import { AuthService, User } from '@auth0/auth0-angular';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '../../api.service';
import { SharedDataService } from '../../shared-data-service.service';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { EMPTY, Observable, Subject, catchError, filter, from, map, startWith, switchMap, take, takeUntil } from 'rxjs';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ErrorModalComponent } from '../../error-modal/error-modal.component';

import { EmployerContactSubscriptionDTO,  AddTransportationPartnerContactLog  } from 'src/app/models/AccountManagementModels';
import { InfoModalComponent } from 'src/app/info-modal/info-modal.component';


@Component({
  selector: 'app-transportation-contact-form',
  templateUrl: './transportation-contact-form.component.html',
  styleUrls: ['./transportation-contact-form.component.css'],
})
export class TransportationContactFormComponent { unsubscribe$ = new Subject<void>();
  employerContactSubscription: EmployerContactSubscriptionDTO;
  //requestForm: FormGroup;
  isLoading = false;
  minDate: Date;
  partnerId: number;
  isMessageSent = false;
  contactId: number;
  userType: string;
  portalURL: string;
  isAuthenticated = false;


  constructor(
    public auth: AuthService,
    private sharedDataService: SharedDataService,
    private apiService: ApiService,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private router: Router,
    private dialog: MatDialog
  ) {

    const today = new Date();
    this.minDate = new Date(today.getFullYear(), today.getMonth(), today.getDate());


  }

  ngOnInit(): void {
    this.auth
      .getAccessTokenSilently()
      .pipe(
        catchError((error) => {
          // Handle errors (e.g., user is not authenticated)
          console.error('Error during silent authentication', error);
          return from(null);
        })
      )
      .subscribe();

    this.isLoading = false;
    // this.route.queryParams.subscribe(params => {
    //   this.partnerId = params['PartnerId'];
    //   if (this.partnerId) {
    //     this.loadHousingPartner(this.partnerId);
    //   }
    // });

    this.auth.user$
      .pipe(
        // Remove null or undefined values
        filter(user => !!user && !!user.nickname),
        // Get the primary employer for the contact
        switchMap(user => {
          const arrMeta = user.nickname.split(':');
          this.contactId = Number.parseInt(arrMeta[1]);
          this.userType = arrMeta[0];
          this.portalURL = this.sharedDataService.getData('portalURL');
          if (this.userType === 'E') {
            return this.apiService.getPrimaryEmployerForContact(this.contactId);
          }

          return EMPTY;
        }),

        // Handle errors in the pipeline
        catchError(e => {
          console.error(e);
          this.openErrorModal(e);
          this.router.navigate(['/error']);
          return EMPTY;
        }),
        
        // Unsubscribe when component is destroyed
        takeUntil(this.unsubscribe$)
      ).subscribe(employerContactSubscription => {
        this.employerContactSubscription = employerContactSubscription;

       });

  }


  onSubmit() {

    let input: AddTransportationPartnerContactLog = {
      transportationPartnerId: 1, // currently only single partner 
      employerContactId: null,
      employerId: null,
      workerId: null
    }

    if(this.auth)
    {
      if(this.userType=='W')
      {
        input.workerId = this.contactId;
      }
      else if(this.userType=='E')
      {
        input.employerContactId = this.employerContactSubscription.contact.employerContactId;
        input.employerId = this.employerContactSubscription.employer.employerId;
      }
    }


    this.apiService.addTransportationPartnerContactLog(input).subscribe();

    window.open("https://www.commutewithenterprise.com/commute/en/partners/form.html?mcid=seasonalconnect:44803894",'_blank');

  }

  // loadHousingPartner(partnerId: number): void {
  //   this.isLoading = true;
  //   this.apiService.getHousingPartner(partnerId).pipe(
  //     catchError(e => {
  //       this.isLoading = false;
  //       console.error(e);
  //       this.openErrorModal(e);
  //       return EMPTY;
  //     })
  //   ).subscribe({
  //     next: (res) => {
  //       this.housingPartner = res;
  //       this.isLoading = false;
  //     },
  //     error: (e) => {
  //       this.isLoading = false;
  //       this.requestForm.get('partnerId').setValue(this.partners[0].id);
  //       this.housingPartner = this.partners.find(p => p.id == this.partners[0].id)

  //       console.error(e);
  //       this.openErrorModal(e);
  //     }
  //   });
  // }

  onCancel() {
    window.location.href = this.portalURL + "/Realtors/PropertySearch";

  }

  openErrorModal(error: any): void {
    const dialogRef = this.dialog.open(ErrorModalComponent, {
      data: error,
      maxWidth: '70vw', // specify max width
      maxHeight: '80vh', // specify max height
      //height: '80vh' // specify height
    });

    // Optionally, you can subscribe to the afterClosed observable to perform actions after the modal is closed.
    dialogRef.afterClosed().subscribe(() => {
      // Perform any actions after the modal is closed (if needed).
    });
  }


  openInfoModal(message: string): void {
    const dialogRef = this.dialog.open(InfoModalComponent, {
      data: { message: message },
      maxWidth: '70vw', 
      maxHeight: '80vh',
    });   
  
    dialogRef.afterClosed().subscribe(() => {
    });
  }

  private extractDateString(date: Date): string {
    if(!date) {
      return null;
    }
    const year = date.getFullYear();
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const day = ('0' + date.getDate()).slice(-2);
    return `${month} / ${day} / ${year} `;
  }
  

}
