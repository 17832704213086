<div class="container" *ngIf="isLoading">
    <app-loading></app-loading>
</div>
<form *ngIf="!isLoading" [formGroup]="signupForm" (ngSubmit)="onSubmit()">
    <mat-card class="">
        <div class="signup-employer-mat-card">
            <h1 class="center-title-join"><span class="center-title-span">Welcome to the </span>Seasonal Connect
                Community
            </h1>
            <h3>Please accept the Terms of Service and the Platform Subscription Agreement prior to using the platform.
            </h3>
            <div formGroupName="section3">
                <mat-card class="my-card">
                    <mat-card-header>
                        <mat-card-title>Terms and Conditions</mat-card-title>
                    </mat-card-header>
                    <mat-card-content>
                        <div class="form-field">
                            <mat-checkbox color="accent" formControlName="termsOfService">
                                I agree to the Terms of Service
                            </mat-checkbox>
                            <button type="button" (click)="openTermsOfService()" mat-icon-button
                                aria-label="Terms of Service">
                                <mat-icon>open_in_new</mat-icon>
                            </button>
                            <mat-error *ngIf="signupForm.get('section3').get('termsOfService').touched && 
                                        signupForm.get('section3').get('termsOfService').hasError('required')">
                                You have to agree to the Terms of Service
                            </mat-error>
                        </div>
                        <div class="form-field">
                            <mat-checkbox formControlName="platformSubscriptionAgreement" color="accent">
                                I agree to the Subscription Agreement
                            </mat-checkbox>
                            <button type="button" (click)="openPlatformSubscriptionAgreement()" mat-icon-button
                                aria-label="Platform Subscription Agreement">
                                <mat-icon>open_in_new</mat-icon>
                            </button>
                            <mat-error
                                *ngIf="signupForm.get('section3').get('platformSubscriptionAgreement').touched && 
                                        signupForm.get('section3').get('platformSubscriptionAgreement').hasError('required')">
                                You have to agree to the Platform Subscription Agreement
                            </mat-error>
                        </div>
                    </mat-card-content>
                </mat-card>
            </div>


            <mat-card-actions>
                <button mat-raised-button class="signup-button" color="primary" type="submit">Accept</button>
            </mat-card-actions>
        </div>
    </mat-card>
</form>