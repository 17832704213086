import { Component, numberAttribute, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { from, of } from 'rxjs';
import { catchError, finalize, switchMap, take } from 'rxjs/operators';
import { JobService } from '../../../services/Job.service';
import { ApiService } from '../../../api.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { BookmarkPositionStatus, HousingImagesDTO } from 'src/app/models/JobModels';
import { ImageModalComponent } from 'src/app/components/image-modal-component/image-modal-component.component';
import { SharedDataService } from 'src/app/shared-data-service.service';
import { InfoModalComponent } from 'src/app/info-modal/info-modal.component';
import { ErrorModalComponent } from 'src/app/error-modal/error-modal.component';
import { AuthService } from '@auth0/auth0-angular';
import { HttpErrorResponse } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';



@Component({
  selector: 'app-job-display',
  templateUrl: './job-display.component.html',
  styleUrls: ['./job-display.component.css']
})
export class JobDisplayComponent implements OnInit {
  @ViewChild('housingDetails') housingDetails!: TemplateRef<any>;
  housingDialogRef!: MatDialogRef<any>;
  @ViewChild('transportationDetails') transportationDetails!: TemplateRef<any>;
  transportationDialogRef!: MatDialogRef<any>;


  bannerImages: any[] = [];
  jobPosition: any = null; // Replace with the `JobPosition` model
  employer: any = null; // Replace with the `Employer` model
  errorMessage: string = '';
  portalURL: string;
  bookmarkPositionStatus: BookmarkPositionStatus

  public isBookmarkLoading: boolean = false;
  public isHideLoading: boolean = false;
  public isPositionLoading: boolean = true;


  googleMapUrl: SafeResourceUrl = '';


  constructor(
    public auth: AuthService,
    private route: ActivatedRoute,
    private router: Router,
    private apiService: ApiService,
    private dialog: MatDialog,
    private sanitizer: DomSanitizer,
    private sharedDataService: SharedDataService,
    private jobService: JobService, // Replace with your actual service
    private snackBar: MatSnackBar,

  ) { }

  ngOnInit(): void {
    this.portalURL = this.sharedDataService.getData('portalURL');

    this.auth.getAccessTokenSilently({ cacheMode: 'cache-only' }).pipe(
      catchError(error => {
        console.warn('Access token error details:', {
          name: error.name,
          message: error.message,
          stack: error.stack,
          error: error.error
        });
        return of(null);
      }),
      finalize(() => {
        this.loadJobData();
      })
    ).subscribe();
  }

  private checkBookmarkData(): void {
    this.route.queryParamMap
      .pipe(
        switchMap((params) => {
          const jobId = params.get('jobId');
          return this.apiService.bookmarkPositionStatus(Number.parseInt(jobId));
        })
      )
      .subscribe((bookmark) => {
        if (bookmark) {
          this.bookmarkPositionStatus = bookmark;
        }
      });
  }
  
  protected toggleBookmark(): void {
    // If disabled, show modal & return
    if (this.bookmarkPositionStatus?.bookmarksDisabled) {
      this.openLimitReachedModal();
      return;
    }
  
    this.isBookmarkLoading = true;
  
    const oldState = this.bookmarkPositionStatus.bookmarked;

    const newState = !oldState;
  
    this.apiService.bookmarkPosition(this.jobPosition.employerPositionId, newState)
      .subscribe({
        next: (resp) => {
          this.bookmarkPositionStatus.bookmarked = newState;
          this.isBookmarkLoading = false;
        },
        error: (err) => {
          this.isBookmarkLoading = false;
        }
      });
  }
  
  
  protected toggleVisibility(): void {
    if (this.bookmarkPositionStatus?.bookmarksDisabled) {
      this.openLimitReachedModal();
      return;
    }
  
    this.isHideLoading = true;
    const oldState = this.bookmarkPositionStatus.hidden;
    const newState = !oldState;
  
    this.apiService.hidePosition(this.jobPosition.employerPositionId, newState)
      .subscribe({
        next: (resp) => {
          this.bookmarkPositionStatus.hidden = newState;
          this.isHideLoading = false;
        },
        error: (err) => {
          // revert or ignore
          this.isHideLoading = false;
        }
      });
  }
  

  public openLimitReachedModal(): void {
    if (this.bookmarkPositionStatus.bookmarksDisabled) {

    this.openInfoModalWithUrlAndTitle('Bookmarks are not inluded in your plan. Please upgrade your subscription to be able to bookmark jobs.', 
      '/change-subscription', 
      'Limit reached');
    }
  }
  

  private loadJobData(): void {

    this.route.queryParamMap
      .pipe(
        switchMap((params) => {
          const jobId = params.get('jobId');
          if (!jobId) {
            this.errorMessage = 'Job ID is missing.';
            return of(null);
          }
          return this.apiService.getPositionsById(Number.parseInt(jobId)).pipe(
            catchError((err: HttpErrorResponse) => {
              this.isPositionLoading = false;
              if (err.status === 401) {
                this.snackBar.open('You need to login to view this job. Redirecting now.', 'Close', { duration: 3000 });

                this.loginWithRedirect();
              } else {
                this.openErrorModal(err);
              }
              return of(null);
            })
          );
        })
      )
      .subscribe((job) => {
        if (job) {
          this.isPositionLoading = false; 
          this.jobPosition = job;
          this.loadEmployerData(job.employerId);
          this.checkBookmarkData();

        }
      });
  }


  loginWithRedirect()
  {
    const currentUrl = this.router.url;
    this.auth.loginWithRedirect({
      appState: { target: currentUrl },
    });
  }

  openErrorModal(error: any): void {
    const dialogRef = this.dialog.open(ErrorModalComponent, {
      data: error,
      maxWidth: '70vw', // specify max width
      maxHeight: '80vh', // specify max height
      //height: '80vh' // specify height
    });

    // Optionally, you can subscribe to the afterClosed observable to perform actions after the modal is closed.
    dialogRef.afterClosed().subscribe(() => {
      window.location.href = this.portalURL;
    });
  }

  private openInfoModalWithUrlAndTitle(message: string, url: string, title: string): void {
    this.dialog.open(InfoModalComponent, {
      data: { message: message, url: url, title: title },
      maxWidth: '70vw',
      maxHeight: '80vh',
    });
  }

  openTransportationModal() {
    this.transportationDialogRef = this.dialog.open(this.transportationDetails, {
      width: '400px'
    });
  }


  openHousingModal() {
    this.housingDialogRef = this.dialog.open(this.housingDetails, {
      width: '400px'
    });
  }


  openImageModal(image: HousingImagesDTO): void {
    this.dialog.open(ImageModalComponent, {
      data: image,
      width: '80vw', // Adjust width as needed
      maxHeight: '80vh', // Adjust max height
    });
  }

  openImageInNewTab(image: HousingImagesDTO): void {
    window.open(image.imageUrl, '_blank');
  }


  closeHousingModal() {
    if (this.housingDialogRef) {
      this.housingDialogRef.close();
    }
  }

  closeTransportationModal() {
    if (this.transportationDialogRef) {
      this.transportationDialogRef.close();
    }
  }

  //change to jobid for public access
  loadEmployerData(employerID: number): void {
    this.apiService.getEmployerForPositionById(employerID).subscribe((employer) => {
      if (employer) {
        this.employer = employer;
        this.bannerImages = employer.banners;

        this.setGoogleMapUrl();

      }
    });

  }

  setGoogleMapUrl(): void {
    if (this.employer.address) {
      const addressForGoogle = this.formatAddressForGoogle(this.employer.address);
      const url = `https://www.google.com/maps/embed/v1/place?q=${addressForGoogle},${this.employer.City} ${this.employer.State} ${this.employer.Zip}&key=AIzaSyDiIsnzlSwhauBQf1N-cDGkqb6_6BSh6PI`;
      this.googleMapUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
    }
  }

  formatAddressForGoogle(address: string): string {
    if (!address) {
      return "";
    }
    if (address.includes('#')) {
      address = address.split('#')[0];
    }
    return encodeURIComponent(address.trim());
  }
}