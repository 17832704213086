import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { faUser, faPowerOff } from '@fortawesome/free-solid-svg-icons';
import { AuthService } from '@auth0/auth0-angular';
import { DOCUMENT } from '@angular/common';
import { ApiService } from 'src/app/api.service';
import { SharedDataService } from 'src/app/shared-data-service.service';
import { bounceIn, fadeIn } from 'ng-animate';
import { transition, trigger, useAnimation } from '@angular/animations';
import { AppInsightsLogginService } from '../../logging.service';
import { Location } from '@angular/common';
import { MatSidenav } from '@angular/material/sidenav';
import { EMPTY, filter, switchMap } from 'rxjs';
import { MatMenuTrigger } from '@angular/material/menu';

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.css'],
  animations: [ trigger('bounceIn', [transition('* => *', useAnimation(bounceIn))]),trigger('fadeIn', [transition('* => *', useAnimation(fadeIn))])],

})
export class NavBarComponent implements OnInit{
  @ViewChild('sidenav') sidenav: MatSidenav;
  @ViewChild('menuTrigger') menuTrigger!: MatMenuTrigger;
  isCollapsed = true;
  faUser = faUser;
  faPowerOff = faPowerOff;
  portalURL: string;
  fadeIn: any;
  userType: string;
  menuOpened: boolean = false;

  constructor(
    public auth: AuthService,
    @Inject(DOCUMENT) private doc: Document,
    private apiService: ApiService,
    private sharedDataService: SharedDataService,
    private appInsights: AppInsightsLogginService,
    private location: Location,
  ) {
    
  }




  ngOnInit() {
    this.appInsights.logTrace(this.location.path());
    this.portalURL = this.sharedDataService.getData('portalURL');
    if(!this.portalURL)    {
      this.apiService.GetConfigurationParam("PortalURL").subscribe(res => { 
        this.portalURL = res; 
        this.sharedDataService.setData<string>('portalURL', res);
      });
    }

    this.auth.user$
      .pipe(
        // Remove null or undefined values
        filter(user => !!user && !!user.nickname),
        // Get the primary employer for the contact
        switchMap(user => {
          const arrMeta = user.nickname.split(':');
          this.userType = arrMeta[0];

          const status = this.getCookie('menuStatus');

          if (status === 'open' || status === null) {
            setTimeout(() => {
              this.menuOpened = true;
              this.menuTrigger.openMenu();
            }, 500);
          }

          return EMPTY;
        })
      ).subscribe(); // <- Add this subscribe call
  }

  // onMenuOpened() {
  //   this.menuOpened = true;
  //   this.setCookie('menuStatus', 'open', 7);
  // }

  // Called when the menu closes
  onMenuClosed() {
    this.menuOpened = false;
    this.setCookie('menuStatus', 'closed', 7);
  }

  toggleMenu() {
    // Prevent the default matMenuTriggerFor click behavior.

    if (this.menuOpened) {
      this.menuTrigger.closeMenu();
      this.menuOpened = false;
      this.setCookie('menuStatus', 'closed', 7); // Store closed state for 7 days.
    } else {
      this.menuTrigger.openMenu();
      this.menuOpened = true;
      this.setCookie('menuStatus', 'open', 7);   // Store open state for 7 days.
    }
  }


  // Utility: sets a cookie with expiration in days.
  setCookie(name: string, value: string, days: number) {
    const d = new Date();
    d.setTime(d.getTime() + (days * 24 * 60 * 60 * 1000));
    const expires = "expires=" + d.toUTCString();
    document.cookie = name + "=" + value + ";" + expires + ";path=/";
  }

  // Utility: retrieves a cookie value by name.
  getCookie(name: string): string | null {
    const nameEQ = name + "=";
    const ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i].trim();
      if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
  }

  loginWithRedirect() {
    this.auth.loginWithRedirect();
  }

  navigateTo(url: string): void {
    window.location.href = url;
}

  logout() {
    this.auth.logout({ logoutParams: { returnTo: this.doc.location.origin } });
  }
}
