<div class="applicant-review-container">
    <h1>Applicant Review</h1>
    <ng-container *ngIf="auth.user$ | async as user; else loading">
    <mat-card class="profile-data-mat-card" *ngIf="auth.user$ | async as user" [@fadeIn]="fadeIn">
        &nbsp;
        <mat-card-content>
          <div *ngIf="userType !== 'E' && portalURL && userType !== 'S'" [@fadeIn]="fadeIn">
            <p>This is not a place for you please return to the <a href="{{ portalURL }}">portal</a>!</p>
          </div>
          <div *ngIf="userType === 'S'" [@fadeIn]="fadeIn">
            <p>
              We are redirecting you to Workers 
              <a *ngIf="portalURL" [@fadeIn]="fadeIn" href="{{ portalURL }}">portal.</a>
              <span *ngIf="!portalURL">portal</span>
            </p>
          </div>

          <mat-expansion-panel [expanded]="!isMobile">
            <mat-expansion-panel-header>
              <mat-panel-title>
                Filter Options
              </mat-panel-title>
            </mat-expansion-panel-header>
          <!-- Search Bar -->
          <div class="search-bar">
            <mat-form-field appearance="outline">
              <mat-label>Search Applicants</mat-label>
              <input matInput [(ngModel)]="searchQuery" placeholder="Enter name or keyword" (keydown.enter)="performSearch()">
              <button mat-icon-button matSuffix (click)="performSearch()">
                <mat-icon>search</mat-icon>
              </button>
            </mat-form-field>
          </div>
          
          <div fxLayout="column" fxLayoutGap="20px">
            <!-- First Row: Position, Date Pickers and Clear Button -->
            <div fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="start center" [formGroup]="filterForm">
              <mat-form-field appearance="outline" fxFlex="25">
                <mat-label>Position</mat-label>
                <mat-select formControlName="position">
                  <mat-option value="">All</mat-option>
                  <mat-option *ngFor="let pos of positions" [value]="pos.employerPositionId">
                    {{ pos.jobTitle }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
    
              <mat-form-field appearance="outline" fxFlex="25">
                <mat-label>Status</mat-label>
                <mat-select formControlName="status">
                  <mat-option value="">All</mat-option>
                  <mat-option *ngFor="let stat of statuses" [value]="stat">
                    {{ stat }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
      
                        <!-- Available Start Date Field -->
            <mat-form-field appearance="outline" fxFlex="25">
              <mat-label>Available Start Date</mat-label>
              <input matInput [matDatepicker]="startPicker" [min]="minDate" [max]="maxDate" formControlName="availableStartDate">
              <mat-datepicker-toggle matSuffix [for]="startPicker"></mat-datepicker-toggle>
              <mat-datepicker #startPicker></mat-datepicker>
              <mat-error *ngIf="filterForm.get('availableStartDate').hasError('outOfRange') && filterForm.get('availableStartDate').touched">
                Start Date out of range.
              </mat-error>
            </mat-form-field>

            <!-- Available End Date Field -->
            <mat-form-field appearance="outline" fxFlex="25">
              <mat-label>Available End Date</mat-label>
              <input matInput [matDatepicker]="endPicker" [min]="minDate" [max]="maxDate" formControlName="availableEndDate">
              <mat-datepicker-toggle matSuffix [for]="endPicker"></mat-datepicker-toggle>
              <mat-datepicker #endPicker></mat-datepicker>
              <mat-error *ngIf="filterForm.get('availableEndDate').hasError('outOfRange') && filterForm.get('availableEndDate').touched">
                End Date out of range.
              </mat-error>
              <mat-error *ngIf="filterForm.get('availableEndDate').hasError('dateRange') && (filterForm.get('availableEndDate').touched || filterForm.get('availableStartDate').touched)">
                End Date should be after Start Date.
              </mat-error>
            </mat-form-field>

      
              <!-- Clear Button Container -->
              <div fxFlex="auto" fxLayout="row" fxLayoutAlign="left center">
                <button mat-button class="clear-button" (click)="clearFilter()">Clear</button>
              </div>
              <div fxFlex="auto" fxLayout="row" fxLayoutAlign="left center">
                <button mat-button class="clear-button" (click)="swithcArchived()" *ngIf="!isArchivedShown">View Archived</button>
                <button mat-button class="clear-button" (click)="swithcArchived()" *ngIf="isArchivedShown">Hide Archived</button>
              </div>
            </div>
          
            <!-- Second Row: Worker Types Checkboxes and Only With Resume -->
            <div fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="start center" [formGroup]="filterForm">
              <div fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="start center">
                <mat-form-field appearance="outline" fxFlex="25">
                  <mat-label>Season</mat-label>
                  <mat-select formControlName="season">
                    <mat-option *ngFor="let seas of seasons" [value]="seas">
                      {{ seas }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                <mat-checkbox formControlName="american">American</mat-checkbox>
                <mat-checkbox formControlName="inCountryH2B">In-Country H-2B</mat-checkbox>
                <mat-checkbox formControlName="outOfCountryH2B">Out of Country H-2B</mat-checkbox>
                <mat-checkbox formControlName="j1">J-1</mat-checkbox>
              </div>
              <mat-checkbox formControlName="onlyWithResume">
                Only show applicants with a résumé
              </mat-checkbox>
            </div>
          </div>

          </mat-expansion-panel>
          &nbsp;
          <mat-spinner *ngIf="isGridLoading" [diameter]="35.8"></mat-spinner>
          <div *ngIf="!isGridLoading" class="grid-loading">
            <div>
              <button mat-raised-button color="primary" (click)="archive()" *ngIf="!isArchivedShown">Archive</button>
              <button mat-raised-button color="primary" (click)="archive()" *ngIf="isArchivedShown">Unarchive</button>
              Click on applicant name to view application
            </div>
            <div>
              <button mat-raised-button color="accent" (click)="exportApplicants()">Export</button>
            </div>
          </div>
          

          <!-- Mobile Filter Trigger -->
          <div class="mobile-filter" *ngIf="isMobile">
            <button mat-icon-button (click)="openFilterModal()">
              <mat-icon class="section-icon">filter_list</mat-icon>
            </button>
          </div>
    
          <!-- Applicants Table -->
          <div class="table-container">
            <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8">
    
              <!-- Archive Column -->
              <ng-container matColumnDef="archive">
                <th mat-header-cell *matHeaderCellDef>
                 <mat-checkbox (change)="masterToggle()" [checked]="isAllSelected()"
                                [indeterminate]="selection.hasValue() && !isAllSelected()">
                  </mat-checkbox>
                </th>
                <td mat-cell *matCellDef="let applicant">
                  <mat-checkbox (click)="$event.stopPropagation()"
                                (change)="$event ? selection.toggle(applicant) : null"
                                [checked]="selection.isSelected(applicant)">
                  </mat-checkbox>
                </td>
              </ng-container>
                  
              <!-- Status Column -->
              <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef>Status</th>
                <td mat-cell *matCellDef="let applicant">
                  <mat-form-field appearance="outline" class="status-select">
                    <mat-select
                      [(ngModel)]="applicant.applicationStatus"
                      (ngModelChange)="onStatusChange($event, applicant)"
                      [compareWith]="compareStatus">
                      <mat-option *ngFor="let stat of statuses" [value]="stat">
                        {{ stat }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </td>
              </ng-container>
              

              <!-- Resume Column -->
              <ng-container matColumnDef="resume">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Resume</th>
                <td mat-cell *matCellDef="let applicant">
                    <div fxLayout="row" fxLayoutAlign="space-between center" style="width: 100%;">
                      <ng-container *ngIf="applicant.incResume">
                        <a *ngIf="applicant.resumeLink" [href]="applicant.resumeLink" download title="Download Resume">
                          <mat-icon class="section-icon">file_download</mat-icon>
                        </a>
                      </ng-container>
                      <a target="_blank" [href]="portalURL + '/SeasonalWorkers/SeasonalWorkerProfile?viewpro=' + applicant.seasonalWorkerId" title="View Worker Profile">
                        <mat-icon class="section-icon">person</mat-icon>
                      </a>
                    </div>
                  </td>
                  
              </ng-container>
    
              <!-- Applicant Name Column -->
              <ng-container matColumnDef="firstName">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Applicant Name</th>
                <td mat-cell *matCellDef="let applicant">
                  <a target="_blank" [href]="portalURL + '/Employers/EmployerSubmittedApplication?viewpro=' + applicant.employerPositionId + '&swid=' + applicant.seasonalWorkerId + '&sub=y&fr=em'">
                    {{ applicant.firstName }} {{ applicant.lastName }}
                  </a>
                </td>
              </ng-container>
    
              <!-- Current Location Column -->
              <ng-container matColumnDef="currentlyInCountry"> 
                <th mat-header-cell *matHeaderCellDef mat-sort-header>In USA</th>
                <td mat-cell *matCellDef="let applicant">
                  {{ applicant.currentlyInCountry ? 'Yes' : 'No' }}
                </td>
              </ng-container>
              
    
    
              <!-- Position Column -->
              <ng-container matColumnDef="position">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Position</th>
                <td mat-cell *matCellDef="let applicant">
                  <a target="_blank" [href]="'jobs/job-display?jobId=' + applicant.employerPositionId">
                    {{ applicant.jobTitle || applicant.position }}
                  </a>
                </td>
              </ng-container>
    
              <!-- Citizenship Column -->
              <ng-container matColumnDef="citizenOfCountry">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Country Of Citizenship</th>
                <td mat-cell *matCellDef="let applicant">{{ applicant.citizenOfCountry }}</td>
              </ng-container>
    
              <!-- Requires Sponsorship Column -->
              <ng-container matColumnDef="requireSponsorship">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Needs Visa Sponsorship</th>
                <td mat-cell *matCellDef="let applicant">
                  {{ applicant.requireSponsorship ? 'Yes' : 'No' }}
                </td>
              </ng-container>
    
              <!-- Sponsor Expire Column -->
              <ng-container matColumnDef="sponsorExpire">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Visa Expiration</th>
                <td mat-cell *matCellDef="let applicant">
                  {{ applicant.sponsorExpire ? (applicant.sponsorExpire | date:'shortDate') : '-' }}
                </td>
              </ng-container>
    
              <!-- H2B Limit Column -->
              <ng-container matColumnDef="h2bLimit">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>3 Year Limit</th>
                <td mat-cell *matCellDef="let applicant">
                  {{ applicant.h2bLimit ? (applicant.h2bLimit | date:'shortDate') : '-' }}
                </td>
              </ng-container>

              <ng-container matColumnDef="dateSubmitted">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Date Submitted</th>
                <td mat-cell *matCellDef="let applicant">
                  {{ applicant.dateSubmitted ? (applicant.dateSubmitted | date:'shortDate') : '-' }}
                </td>
              </ng-container>
    
    
              <!-- Header and Row Declarations -->
              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
    
            <!-- The paginator now triggers an event on any change -->
            <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]" showFirstLastButtons (page)="performSearch()"></mat-paginator>
          </div>
        </mat-card-content>
      </mat-card>
    </ng-container>
    <ng-template #loading>
      <div class="spinner-container">
        <mat-spinner diameter="80"></mat-spinner>
      </div>
    </ng-template>
  </div>
  