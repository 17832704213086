<div class="container" *ngIf="auth.isLoading$ | async; else loaded">
  <app-loading></app-loading>
  </div>
  
  <ng-template #loaded>
    <div class="resources">
      <div class="header">
        <h1>Resources</h1>
      </div>
      <p class="subtitle">Please find below a list of resources we offer for our Seasonal Connect members. Click on an icon below to take you to resources for that topic.</p>
      <div class="resources-grid"  [@fadeIn]="fadeIn">
        <div class="resource-box" (click)="navigate('1')">
          <div class="icon-container">
            <div class="icon-background recruitment"></div>
            <fa-icon [icon]="faUsers" class="icon outlined"></fa-icon>
          </div>
          <span>Recruitment</span>
        </div>
        <div class="resource-box" (click)="navigate('2')">
          <div class="icon-container">
            <div class="icon-background staff-management rotate-90 "></div>
            <fa-icon [icon]="faUsersGear" class="icon outlined"></fa-icon>
          </div>
          <span>Staff Management</span>
        </div>
        <div class="resource-box" (click)="navigate('3')">
          <div class="icon-container">
            <div class="icon-background housing-transportation rotate-45"></div>
            <fa-icon [icon]="faPeopleRoof" class="icon outlined"></fa-icon>
          </div>
          <span>Housing & Transportation</span>
        </div>
        <div class="resource-box" (click)="navigate('4')">
          <div class="icon-container">
            <div class="icon-background immigration rotate-130"></div>
            <fa-icon [icon]="faEarthAmericas" class="icon outlined"></fa-icon>
          </div>
          <span>Immigration</span>
        </div>
        <div class="resource-box" (click)="navigate('5')">
          <div class="icon-container">
            <div class="icon-background miscellaneous  rotate-245"></div>
            <fa-icon [icon]="faEllipsis" class="icon outlined"></fa-icon>
          </div>
          <span>Miscellaneous</span>
        </div>
        <div class="network-resources" *ngIf="networkResourceTopics.length > 0">
          <div class="resources-grid" [@fadeIn]="fadeIn">
            <div
              class="resource-box"
              *ngFor="let topic of networkResourceTopics"
              (click)="navigate(topic.resourceTopicId)"
            >
              <div class="icon-container">
                <div class="icon-background network-icon-background"></div>
                <img *ngIf="topic.topicImage"
                  [src]="topic.topicImage"
                  class="icon outlined img-icon"
                />
                <fa-icon *ngIf="!topic.topicImage" [icon]="faComments" class="icon outlined"></fa-icon>

              </div>
              <span>{{ topic.name }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="parallax-container"  [@fadeIn]="fadeIn">
      <div class="parallax-image parallax-left" style="background-image: url('/assets/blue.png');"></div>
      <div class="parallax-image parallax-middle" style="background-image: url('/assets/red.png');"></div>
      <div class="parallax-image parallax-right" style="background-image: url('/assets/yellow.png');"></div>
    </div>
  </ng-template>