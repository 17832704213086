
<mat-card class="">
    <div class="signup-done-mat-card">
      <h2 class="center-title">Thank you!</h2>

      <mat-card-content>
        <!-- Section 1: Company Details -->
        <!-- <mat-card-title class="center-title"><strong>Company Details</strong></mat-card-title> -->
        <div >
          <p> 
            Congratulations! Your signup was successful. 
            You can login now.
            
          </p>
          <div class="center" *ngIf="portalURL">
            <a mat-raised-button [href]="portalURL">Log in <i class="arrow-right"></i> </a>
          </div>
        </div>
        
      </mat-card-content>
    </div>
  </mat-card>

