import { Component, OnInit } from '@angular/core';
import { ApiService } from '../api.service';
import { SharedDataService } from '../shared-data-service.service';
import { AuthService } from '@auth0/auth0-angular';

@Component({
  selector: 'app-signup-worker-done',
  templateUrl: './signup-worker-done.component.html',
  styleUrls: ['./signup-worker-done.component.css']
})
export class SignupWorkerDoneComponent implements OnInit {
  portalURL: string;
  constructor(
    public auth: AuthService,
    private apiService: ApiService,
    private sharedDataService: SharedDataService,
  ) {
    
  }

  ngOnInit() {
    this.portalURL = this.sharedDataService.getData('portalURL');
    if(!this.portalURL)    {
      this.apiService.GetConfigurationParamForSignup("PortalURL").subscribe(res => { 
        this.portalURL = res; 
        this.sharedDataService.setData<string>('portalURL', res);
      });
    }
  }

  loginWithRedirect() {
    this.auth.loginWithRedirect();
  }
}
