import { Component } from '@angular/core';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { HttpClient } from '@angular/common/http';
import { Package } from 'src/app/models/ChargeOverModels';
import { AuthService, User } from '@auth0/auth0-angular';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '../../api.service';
import { SharedDataService } from '../../shared-data-service.service';
import { EMPTY, Observable, Subject, catchError, filter, from, map, startWith, switchMap, take, takeUntil } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { ErrorModalComponent } from '../../error-modal/error-modal.component';
import { EmployerContactSubscriptionDTO, ChangePlanRequest, Contact } from 'src/app/models/AccountManagementModels';
import { InfoModalComponent } from 'src/app/info-modal/info-modal.component';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'app-sort-contacts',
  templateUrl: './sort-contacts.component.html',
  styleUrls: ['./sort-contacts.component.css']
})
export class SortContactsComponent {
  unsubscribe$ = new Subject<void>();
  contacts: Contact[];
  isLoading = true;
  isLoadingOrder = true;
  employerContactSubscription: EmployerContactSubscriptionDTO;
  employerContactId: number;
  userType: string;
  portalURL: string;
  adminLimit: number;
  adminText: string;
  adminUrl: string;

  constructor(private http: HttpClient,
    public auth: AuthService,
    private sharedDataService: SharedDataService,
    private apiService: ApiService,
    private route: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
    private sanitizer: DomSanitizer) {
  }

  ngOnInit(): void {
    this.isLoading = true;

    this.auth.user$
      .pipe(
        // Remove null or undefined values
        filter(user => !!user && !!user.nickname),
        // Get the primary employer for the contact
        switchMap(user => {
          const arrMeta = user.nickname.split(':');
          this.employerContactId = Number.parseInt(arrMeta[1]);
          this.userType = arrMeta[0];
          this.portalURL = this.sharedDataService.getData('portalURL');
          this.adminUrl = this.portalURL + "/Employers/EmployerDetail?a=Link&type=panContact"
          if (this.userType === 'E') {
            return this.apiService.getPrimaryEmployerForContact(this.employerContactId);
          }


          return EMPTY;
        }),

        // Handle errors in the pipeline
        catchError(e => {
          console.error(e);
          this.openErrorModal(e);
          this.router.navigate(['/error']);
          return EMPTY;
        }),

        // Unsubscribe when component is destroyed
        takeUntil(this.unsubscribe$)
      ).subscribe(employerContactSubscription => {
        this.employerContactSubscription = employerContactSubscription;
        const acceptTerms = this.employerContactSubscription.employer.acceptTerms;
        const acceptSubscription = this.employerContactSubscription.employer.acceptSubscription;
        // Check if either date is null or older than the SC open date
        if ((acceptTerms === null || new Date(acceptTerms) < new Date('2021-01-01')) ||
          (acceptSubscription === null || new Date(acceptSubscription) < new Date('2021-01-01'))) {
          this.router.navigate(['/accept-terms']);
          return;
        }

        this.apiService.IsFeatureLimited(this.employerContactSubscription.employer.employerId, "n_user_login")
          .pipe(
            catchError(e => {
              console.error(e);
              this.openErrorModal(e);
              this.isLoading = false;
              //this.router.navigate(['/error']);
              return EMPTY;
            }),
            takeUntil(this.unsubscribe$)
          )
          .subscribe(data => {
            this.adminLimit = data;
            this.isLoading = false;
          });
        this.getContacts();
      });

  }

  getContacts() {
    this.isLoadingOrder = true;
    this.apiService.getContacts(this.employerContactSubscription.employer.employerId)
      .pipe(
        catchError(e => {
          console.error(e);
          this.openErrorModal(e);
          this.isLoadingOrder = false;
          //this.router.navigate(['/error']);
          return EMPTY;
        }),
        takeUntil(this.unsubscribe$)
      )
      .subscribe(data => {
        this.contacts = data;
        this.isLoadingOrder = false;
      });
  }

  submit() {
    this.employerContactSubscription.plan.planId < 3
    this.isLoadingOrder = true;
    this.apiService.updateContacts(this.contacts)
      .pipe(
        catchError(e => {
          console.error(e);
          this.openErrorModal(e);
          this.isLoadingOrder = false;
          //this.router.navigate(['/error']);
          return EMPTY;
        }),
        takeUntil(this.unsubscribe$)
      )
      .subscribe(data => {
        this.isLoadingOrder = false;
        this.getContacts();
      });
  }

  drop(event: CdkDragDrop<string[]>) {


    // Check if the user is trying to move the contact with ContactType 'A' away from the first position
    // or if the user is trying to move a different contact to the first position
    // Get reference to the dragged contact and the contact at the target index
    const draggedContact = this.contacts[event.previousIndex];
    const targetContact = this.contacts[event.currentIndex];
    const secondContact = this.contacts[1]

    // Abort the operation if a non-admin contact is being moved to the first position
    if (event.currentIndex === 0 && draggedContact.contactType !== 'A') {
      {
        this.openInfoModal("An Admin is required to be in the first spot.  To change a user's account type click the \"Open\" button below.", this.adminUrl)
        return;
      }

    }

    // Abort the operation if a user is trying to swap with an admin at the second position
    if (event.previousIndex === 0 && draggedContact.contactType === 'A' && secondContact.contactType !== 'A') {
      this.openInfoModal("An Admin is required to be in the first spot.  To change a user's account type click the \"Open\" button below.", this.adminUrl)
      return;
    }

    // Otherwise, proceed with the move
    else {
      moveItemInArray(this.contacts, event.previousIndex, event.currentIndex);
    }

    // Update SortOrderAdmin values
    this.contacts.forEach((contact, index) => contact.sortOrderAdmin = index + 101);
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  getRole(role: string): string {
    switch (role) {
      case "A":
        return 'Admin';
      case "P":
        return 'User';
      default:
        return 'Other';
    }
  }

  openErrorModal(error: any): void {
    const dialogRef = this.dialog.open(ErrorModalComponent, {
      data: error,
      maxWidth: '70vw', // specify max width
      maxHeight: '80vh', // specify max height
      //height: '80vh' // specify height
    });

    // Optionally, you can subscribe to the afterClosed observable to perform actions after the modal is closed.
    dialogRef.afterClosed().subscribe(() => {
      // Perform any actions after the modal is closed (if needed).
    });
  }
  openInfoModal(message: string, url?: string): void {
    const dataObject = { message: message };

    if (url) {
      dataObject['url'] = url;
    }

    const dialogRef = this.dialog.open(InfoModalComponent, {
      data: dataObject,
      maxWidth: '70vw',
      maxHeight: '80vh',
    });

    dialogRef.afterClosed().subscribe(() => {
      // Handle the modal close event if needed
    });
  }

}
