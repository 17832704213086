<mat-card class="error-mat-card" >
  <!-- User Profile Info -->
  <mat-card-header>
      <mat-card-title>Something went wrong</mat-card-title>
  </mat-card-header>
<br />
  <mat-card-content>
     <p>Please contact support at info@seasonalconnect.com</p>
  </mat-card-content>
</mat-card>

