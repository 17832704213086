import { Component } from '@angular/core';
import { AppInsightsLogginService } from './logging.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent {
  constructor(private appInsightsLoggingService: AppInsightsLogginService) {
 
  }

  title = 'Seasonal Connect';
}
