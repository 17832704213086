
    <mat-card class="">
      <div class="signup-done-mat-card">
        <h2 class="center-title">Thank you!</h2>
  
        <mat-card-content>
          <!-- Section 1: Company Details -->
          <!-- <mat-card-title class="center-title"><strong>Company Details</strong></mat-card-title> -->
          <div *ngIf="employerData">
            <p> 
              Congratulations {{employerData.firstName}}! Your signup was successful. To ensure that our members have an excellent experience, our team carefully reviews each member of Seasonal Connect before they can officially sign-up. Once {{employerData.companyName}} has been approved, you will receive an email from our team and you will be able to enter the platform.
            </p>
          </div>
          <div *ngIf="!employerData">
            <p> 
              Congratulations! Your payment was successful. To ensure that our members have an excellent experience, our team carefully reviews each member of Seasonal Connect before they can officially sign-up. Once your organisation has been approved, you will receive an email from our team and you will be able to enter the platform.
            </p>
          </div>
        </mat-card-content>
      </div>
    </mat-card>

  