import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export interface FilterData {
  selectedFilter: {
    position: string;
    startDate: string;
    onlyWithResume: boolean;
  };
}

@Component({
  selector: 'app-applicant-filter-modal',
  templateUrl: './applicant-filter-modal-component.component.html'
})
export class ApplicantFilterModalComponent {
  // Change filterValue to be an object matching the expected filter structure.
  filterValue: { position: string; startDate: string; onlyWithResume: boolean };

  constructor(
    public dialogRef: MatDialogRef<ApplicantFilterModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: FilterData
  ) {
    // Copy the filter object so modifications here won’t immediately affect the parent data.
    this.filterValue = { ...data.selectedFilter };
  }

  // Call this method when the user applies the filter.
  apply(): void {
    this.dialogRef.close(this.filterValue);
  }

  // Optional: a separate method to close without applying changes.
  close(): void {
    this.dialogRef.close();
  }
}
