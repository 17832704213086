// error-modal.component.ts
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-error-modal',
  templateUrl: './error-modal.component.html',
styleUrls: ['./error-modal.component.css']
})
export class ErrorModalComponent {
  errorMessage: string;
  constructor(@Inject(MAT_DIALOG_DATA) public data:  any,
  public dialogRef: MatDialogRef<ErrorModalComponent>) {
    
    if (data.error && data.error.errorMessage) {
      this.errorMessage = data.error.errorMessage;
    } else if (data.errorMessage && typeof data.errorMessage === 'string') {
      this.errorMessage = data.errorMessage;
    } else if (data.error) {
      this.errorMessage = data.error;
    } else {
      this.errorMessage = JSON.stringify(data);
    }
    if (typeof this.errorMessage === 'string') {
      this.errorMessage = this.stripQuotes(this.errorMessage);
    } else {
      this.errorMessage = null;
    }
    console.log(data);
  }

  // Helper function to remove starting and ending quotation marks
  stripQuotes(str: string): string {
    return str.replace(/^"|"$/g, '');
  }

  closeModal() {
    this.dialogRef.close()
  }
}
