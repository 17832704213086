<footer id="footer" class="footer">
  <div class="wrap">
    <div class="inner-wrap">
      <div class="rha-disclaimer"  *ngIf="showRhaDisclaimer && auth.user$ | async as user "  [@fadeIn]="fadeIn">
        <h3>RHA Member Disclaimer</h3>
        <p>
          Seasonal Connect is a third-party vendor connecting employers with workers for seasonal job opportunities. By
          using this platform, employers acknowledge and agree that Seasonal Connect, its members, its sponsors, and its
          partners, including RHA, are not liable for any acts, errors, omissions, or misconduct of any workers
          participating in the Seasonal Connect community, whether they are in the platform and/or working for an
          employer that is a Seasonal Connect member.
        </p>

        <p>
          Employers are solely responsible for conducting all necessary due diligence when hiring workers, which may
          include but is not limited to completion of background checks, verification of credentials, and compliance
          with federal, state, and local labor and employment laws. Employers agree to take full responsibility for
          their hiring decisions and the actions of their employees.
        </p>

        <p>
          Seasonal Connect and RHA, expressly disclaim all liability for any loss, damages, or disputes that may arise
          from interactions, contracts, or work performed between employers and workers on this platform. All users of
          the platform acknowledge the disclaimer of liability by use of the platform, agree to these terms, and assume
          any risks associated with engagement of workers through Seasonal Connect.
        </p>
      </div>
      <div class="border-top"></div>
      <div class="footer-left">
        <div class="copyright">© SeasonalConnect, LLC</div>
        <nav class="conditions" *ngIf="portalURL">
          <a [href]="portalURL + '/TermsAccept'">Terms &amp; Conditions</a>
          <a [href]="portalURL + '/Privacy'">Privacy Policy</a>
          <a [href]="portalURL + '/Disclaimer'">Disclaimer</a>
          <a [href]="portalURL + '/RefundPolicy'">Refund Policy</a>
        </nav>
      </div>
      <div class="footer-right">
        <div class="social-icons">
          <a href="https://www.facebook.com/seasonalconnect" target="_blank">
            <mat-icon class="social-icon">facebook</mat-icon>
          </a>
          <a href="https://www.linkedin.com/company/seasonalconnect" target="_blank">
            <img src="assets/linkedin.svg" alt="LinkedIn" class="social-icon linkedin" />
          </a>
        </div>
      </div>
      
    </div>
  </div>
</footer>