<mat-dialog-content class="modal-container">
    <div class="modal-header">
      <button mat-icon-button class="close-button" (click)="closeDialog()">
        <mat-icon>close</mat-icon>
      </button>
    </div>

<div class="container" *ngIf="!safeContent" [@fadeIn]="fadeIn">
  <app-loading></app-loading>
</div>  
<div class="modal-body" *ngIf="safeContent" [@fadeIn]="fadeIn">
  <p [innerHTML]="safeContent"></p>  <!-- Displaying the content here -->
</div>

<div class="modal-footer">
  <button mat-raised-button color="primary" (click)="acceptTerms()">I Accept</button>
</div>
</mat-dialog-content>