import { Component, OnInit } from '@angular/core';
import { AuthService, User } from '@auth0/auth0-angular';
import { ApiService } from 'src/app/api.service';
import {  Router } from '@angular/router';
import { filter } from 'rxjs';
import { faUsers, faUsersGear, faPeopleRoof, faEarthAmericas, faEllipsis, faComments } from '@fortawesome/free-solid-svg-icons';
import { trigger, transition, useAnimation } from '@angular/animations';
import { bounceIn, fadeIn } from 'ng-animate';
import { ResourceTopic } from 'src/app/models/AccountManagementModels';




@Component({
  selector: 'resources-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.css'],
  animations: [ trigger('bounceIn', [transition('* => *', useAnimation(bounceIn))]),trigger('fadeIn', [transition('* => *', useAnimation(fadeIn))])],

})
export class ResourcesIndexComponent implements OnInit {
  faUsers = faUsers;
  faUsersGear = faUsersGear;
  faPeopleRoof = faPeopleRoof;
  faEarthAmericas = faEarthAmericas;
  faEllipsis = faEllipsis;
  faComments = faComments;

  bounceIn: any;
  fadeIn: any;
  isRha: boolean = false;

  networkResourceTopics: ResourceTopic[] = [];

  constructor(
    public auth: AuthService,
    private router: Router,
    private apiService: ApiService // Inject ApiService
  ) {}

  navigate(topic: string): void {
    this.router.navigate(['/resources/resource-detail'], { queryParams: { topic } });
  }

  ngOnInit() {
    this.auth.user$
      .pipe(
        filter(user => !!user && !!user.nickname),
      )
      .subscribe();

    this.fetchNetworkResourceTopics();
  }

  fetchNetworkResourceTopics() {
    this.apiService.getNetworkResourceTopics().subscribe(
      (topics: ResourceTopic[]) => {
        this.networkResourceTopics = topics;

      },
      (error) => {
        console.error('Error fetching network resource topics:', error);
      }
    );
  }
}