<!-- Check if customer is available and if data is still loading -->
<div class="container" *ngIf="!customer && auth.isLoading$ | async; else loaded">
  <app-loading></app-loading>
</div>

<ng-template #loaded>
  <h1 class="center-title" [@fadeIn]="fadeIn">Account Management</h1>
  <mat-card class="profile-data-mat-card" *ngIf="auth.user$ | async as user" [@fadeIn]="fadeIn">
    <!-- User Profile Info -->
    <mat-card-header>
      <mat-card-title>Hi {{ user.name }}!</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <div *ngIf="userType !== 'E' && portalURL && userType !== 'S'" [@fadeIn]="fadeIn">
        <p> This is not a place for you please return to the <a href="{{ portalURL }}">portal</a> !</p>
      </div>
      <div  *ngIf="userType === 'S'" [@fadeIn]="fadeIn">
        <p> We are redirecting you to Workers 
          <a *ngIf="portalURL" [@fadeIn]="fadeIn" href="{{ portalURL }}">portal.</a>
          <span *ngIf="!portalURL">portal</span>
          </p>
      </div>
      <div *ngIf="!employerContactSubscription && userType === 'E'"><app-loading></app-loading></div>
      <!-- Company Info -->
      <div *ngIf="employerContactSubscription">
        <h2>Overview</h2>
        <p>You are managing: <strong>{{ employerContactSubscription.employer.dba }}.</strong></p>
        <p>Current subscription status is: <mat-spinner class="inline-spinner" *ngIf="!package"
            [diameter]="15"></mat-spinner> <strong *ngIf="package" [@fadeIn]="fadeIn">{{
            getSubscriptionStatus(package.package_status_str) }}</strong>
          <span *ngIf="package && package?.package_status_state === 'a'"> and plan is <mat-spinner
              class="inline-spinner" *ngIf="!package" [diameter]="15"></mat-spinner> <strong *ngIf="package"
              [@fadeIn]="fadeIn">{{ package.line_items[0].item_name }}</strong></span>
        </p>
        <p>Current account status is: <strong>{{
            getCustomerApprovalStatus(employerContactSubscription.employer.customerApprovalStatusId) }}
          </strong></p>
        <div
          *ngIf="employerContactSubscription.employer.customerApprovalStatusId == 2 || employerContactSubscription.employer.customerApprovalStatusId == 3"
          class="expansion-panel" fxLayout="row" fxLayoutGap="10px" fxLayoutGap.lt-sm="0px">

          <mat-expansion-panel [expanded]="true">
            <mat-expansion-panel-header class="expansion-panel-account-status">
              <mat-panel-title>
                Note About Your Account Status
              </mat-panel-title>
            </mat-expansion-panel-header>
            <p *ngIf="employerContactSubscription.employer.customerApprovalStatusId == 2">Thank you for signing up for
              Seasonal Connect! We review each organization that signs up prior to approving them to join the community.
              This ensures that each member within the Seasonal Connect platform suits the needs of the community and
              aligns with our mission.
              <br />Our team will review your submission and then you will be able to login to the portal. You are only
              a few short steps away from becoming a member of the Seasonal Connect community!
            </p>
            <p *ngIf="employerContactSubscription.employer.customerApprovalStatusId == 3">Thank you for your interest in
              Seasonal Connect. We review each organization that signs up prior to approving them to join the community
              to ensure that each member within the Seasonal Connect platform suits the needs of the community and
              aligns with our mission.
              <br />Upon review of your sign-up submission, we are unable to approve you to join the Seasonal Connect
              community. We appreciate your interest in the platform.
            </p>
          </mat-expansion-panel>
        </div>
        <br />
        <div
        *ngIf="cancelReport"
        class="expansion-panel" fxLayout="row" fxLayoutGap="10px" fxLayoutGap.lt-sm="0px">

        <mat-expansion-panel class="expansion-panel" [expanded]="true">
          <mat-expansion-panel-header class="expansion-panel-downgrade">
            <mat-panel-title>
              Note About Your Plan Change
            </mat-panel-title>
          </mat-expansion-panel-header>
          <p>You have canceled your subscription. Your account will be active until {{ cancelReport?.package_scheduled_cancel_scheduled_date | date:"MM/dd/yyyy" }}.
          </p>

        </mat-expansion-panel>
      </div>
      <div
        *ngIf="downgradeReport"
        class="expansion-panel" fxLayout="row" fxLayoutGap="10px" fxLayoutGap.lt-sm="0px">

        <mat-expansion-panel class="expansion-panel" [expanded]="true">
          <mat-expansion-panel-header class="expansion-panel-downgrade">
            <mat-panel-title >
              Note About Your Plan Change 
            </mat-panel-title>
          </mat-expansion-panel-header>
          <p>You have downgraded your subscription. Current plan is active until {{ downgradeReport?.package_scheduled_cancel_scheduled_date | date:"MM/dd/yyyy" }}.
          </p>

        </mat-expansion-panel>
      </div>
        <div *ngIf="employerContactSubscription.employer.customerApprovalStatusId != 3">
          <p>By visiting the links below you can change your payment method or view your statements on our service
            provider Chargeover</p>

          <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="16px">
            <!-- Statement and Payment Method buttons -->
            <div class="actions" fxFlex="50">
              <mat-card-header style="padding:0;">
                <mat-card-title>Payment Methods</mat-card-title>
              </mat-card-header>
              <button mat-raised-button color="primary" (click)="navigateTo(customer?.url_paymethodlink)">Update Payment
                Method</button>
              <p *ngIf="package" [@fadeIn]="fadeIn">Current: {{ package?.paymethod_name }} - {{
                package?.paymethod_detail }}</p>
            </div>
            <div class="actions" fxFlex="50">
              <mat-card-header style="padding:0;">
                <mat-card-title>Subscription</mat-card-title>
              </mat-card-header>
              <button *ngIf="package && package?.package_status_state != 'a'" mat-raised-button
                color="accent" [routerLink]="['/buy-subscription']" [@fadeIn]="fadeIn">Buy</button>
              <button *ngIf="package?.package_status_state === 'a'" mat-raised-button class="light-blue"
                [routerLink]="['/change-subscription']" [@fadeIn]="fadeIn">Change</button>
                <!-- <button *ngIf="package?.package_status_state === 'a'" mat-raised-button class="fake-disabled"
                (click)="openInfoMessageDialog()" [@fadeIn]="fadeIn">Change</button> -->
              <button *ngIf="package?.package_status_state === 'a' && !cancelReport" mat-raised-button
                [routerLink]="['/cancel-subscription']" [@fadeIn]="fadeIn">Cancel</button>
            </div>
          </div>
          <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="16px">
            <!-- Statement and Payment Method buttons -->
            <div class="actions" fxFlex="50">
              <mat-card-header style="padding:0;">
                <mat-card-title>Statements</mat-card-title>
              </mat-card-header>
              <button mat-raised-button color="primary" (click)="navigateTo(customer?.url_statementlink)">Download
                Statements</button>
            </div>
            <div class="actions" fxFlex="50">
              <mat-card-header style="padding:0;">
                <mat-card-title>Need Help?</mat-card-title>
              </mat-card-header>
              <a mat-raised-button color="accent" href="mailto:info@seasonalconnect.com">Email Us</a> or call us at
              <strong>(617)716-6491</strong>

            </div>
          </div>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
  <br />
  <div class="profile-data-mat-card" *ngIf="employerContactSubscription && userType === 'E'" [@fadeIn]="fadeIn">
    <div *ngIf="employerContactSubscription.employer.customerApprovalStatusId != 3">
      <!-- User Profile Info -->
      <mat-card-header>
        <mat-card-title>Invoices </mat-card-title>
      </mat-card-header>
      <app-loading *ngIf="!invoices && employerContactSubscription && userType === 'E'"></app-loading>
    </div>
    <br />
    <div *ngIf="invoices" class="profile-data-mat-card" [@fadeIn]="fadeIn">
      <table mat-table [dataSource]="invoices" class="mat-elevation-z8">

        <!-- Invoice ID Column -->
        <ng-container matColumnDef="invoice_id">
          <th mat-header-cell *matHeaderCellDef> Invoice # </th>
          <td mat-cell *matCellDef="let invoice">
            <a href="#" (click)="navigateTo(invoice.url_permalink)">{{ invoice.invoice_id }}</a>
          </td>
        </ng-container>

        <!-- Issued At Column -->
        <ng-container matColumnDef="date">
          <th mat-header-cell *matHeaderCellDef> Issued On </th>
          <td mat-cell *matCellDef="let invoice"> {{ invoice.date | date:"MM/dd/yyyy" }} </td>
        </ng-container>

        <!-- Due Date Column -->
        <ng-container matColumnDef="due_date">
          <th mat-header-cell *matHeaderCellDef> Due Date </th>
          <td mat-cell *matCellDef="let invoice"> {{ invoice.due_date | date:"MM/dd/yyyy" }} </td>
        </ng-container>

        <!-- Status Column -->
        <ng-container matColumnDef="invoice_status_name">
          <th mat-header-cell *matHeaderCellDef> Status </th>
          <td mat-cell *matCellDef="let invoice"> {{ invoice.invoice_status_name }} </td>
        </ng-container>

        <!-- Total Amount Column -->
        <ng-container matColumnDef="total">
          <th mat-header-cell *matHeaderCellDef> Total </th>
          <td mat-cell *matCellDef="let invoice"> {{ invoice.total | currency:'USD':'symbol':'.2-2' }}</td>
        </ng-container>

        <!-- Amount Paid Column -->
        <ng-container matColumnDef="payments">
          <th mat-header-cell *matHeaderCellDef> Amount Paid </th>
          <td mat-cell *matCellDef="let invoice"> {{ invoice.payments| currency:'USD':'symbol':'.2-2' }} </td>
        </ng-container>

        <!-- Amount Due Column -->
        <ng-container matColumnDef="balance">
          <th mat-header-cell *matHeaderCellDef> Amount Due </th>
          <td mat-cell *matCellDef="let invoice"> {{ invoice.balance | currency:'USD':'symbol':'.2-2' }} </td>
        </ng-container>

        <!-- Actions Column for Buttons -->
        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef> Actions </th>

          <td mat-cell *matCellDef="let invoice">
            <a *ngIf="invoice.balance > 0" mat-button (click)="navigateTo(invoice.url_paylink)"><fa-icon
                [icon]="faCashRegister" class="mr-3"></fa-icon>Pay</a>
            <a mat-button (click)="navigateTo(invoice.url_pdflink)"><fa-icon [icon]="faDownload"
                class="mr-3"></fa-icon>PDF</a>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </div>
  </div>
  <br />
</ng-template>