<mat-toolbar class="mat-toolbar" >
  <div class="container toolbar-container" >
    <span class="logo logo-box"><a routerLink="/"><img src="../../assets/color.svg" alt=""></a></span>
    <span class="spacer"></span>

    <ng-container *ngIf="(auth.isAuthenticated$ | async) === true && userType"  >
      <!-- Centered Menu -->
      <div class="centered-menu" *ngIf="(auth.isAuthenticated$ | async) === true"  [@fadeIn]="fadeIn" >
        <a mat-button class="top-center-nav-button" [href]="portalURL">Dashboard</a>
        <a mat-button class="top-center-nav-button" [href]="portalURL + '/Employers/EmployerSearch'">Employer Search</a>
        <a *ngIf="userType != 'S'" mat-button class="top-center-nav-button" [href]="portalURL + '/SeasonalWorkers/SeasonalWorkerSearch'">Worker Search</a>
        <a *ngIf="userType == 'S'" mat-button class="top-center-nav-button" [href]="portalURL + '/Employers/JobSearch'">Worker Search</a>
      </div>

      <!-- Profile Menu on the Right for desktop -->
      <mat-menu #profileMenuDesktop="matMenu"  [@fadeIn]="fadeIn" >         <!-- Networks -->
        <mat-nav-list *ngIf="(auth.isAuthenticated$ | async) === true">
          <!-- <a mat-list-item href="../#">Networks</a> -->
          <a mat-list-item [href]="portalURL + '/EventLanding'" class="custom-hover-style-menu">Events</a>
          <a mat-list-item [href]="portalURL + '/About.aspx'" class="custom-hover-style-menu">About</a>
          <a mat-list-item [href]="portalURL + '/FAQs.aspx'" class="custom-hover-style-menu">FAQs</a>
          <a mat-list-item [href]="portalURL + '/Contact.aspx'" class="custom-hover-style-menu">Contact</a>
          <a mat-list-item *ngIf="userType && userType != 'S'"  routerLink="/resources" class="custom-hover-style-menu">Resources</a>
        
          <!-- My Account (Collapsible Sub Menu) -->
          <mat-expansion-panel *ngIf="(auth.isAuthenticated$ | async) === true" [expanded]="true" (click)="$event.stopPropagation()" [style]="{ 'background-color': 'transparent' }">
            <mat-expansion-panel-header>
              My Account
            </mat-expansion-panel-header>
            
            <a mat-list-item *ngIf="userType && userType != 'S'" [href]="portalURL + '/Employers/EmployerDetail.aspx'" class="custom-hover-style-menu">Edit Profile</a>
            <a mat-list-item *ngIf="userType && userType == 'S'" [href]="portalURL + '/SeasonalWorkers/SeasonalWorkerDetail.aspx'" class="custom-hover-style-menu">Edit Profile</a>
            <a mat-list-item [href]="portalURL + '/Bookmarks.aspx'" class="custom-hover-style-menu">My Bookmarks</a>
            <a mat-list-item *ngIf="userType && userType != 'S'" [href]="portalURL + '/Employers/JobOffers'" class="custom-hover-style-menu">My Job Offers</a>
            <a mat-list-item *ngIf="userType && userType == 'S'" [href]="portalURL + '/SeasonalWorkers/WorkerJobOffers'" class="custom-hover-style-menu">My Job Offers</a>
            <a mat-list-item *ngIf="userType && userType != 'S'" [href]="portalURL + '/Employers/Employeropenapplications'" class="custom-hover-style-menu">My Applications</a>
            <a mat-list-item *ngIf="userType && userType == 'S'" [href]="portalURL + '/SeasonalWorkers/SeasonalWorkerapplications'" class="custom-hover-style-menu">My Applications</a>
            <a mat-list-item  *ngIf="userType && userType != 'S'" routerLink="/"  class="custom-hover-style-menu">Account Management</a>
            <a mat-list-item (click)="logout()"  class="custom-hover-style-menu">Logoff</a>
          </mat-expansion-panel>
        
          <!-- Social Icons and Close Menu -->
          <div class="menu-footer">
            <div class="social-icons">
              <a href="https://www.facebook.com/seasonalconnect" target="_blank">
                <mat-icon class="social-icon">facebook</mat-icon>
              </a>
              <a href="https://www.linkedin.com/company/seasonalconnect" target="_blank">
                <img src="assets/linkedin.svg" alt="LinkedIn" class="social-icon linkedin" />
              </a>
            </div>
            <span class="btn-close-menu">Close Menu</span>
          </div>
        </mat-nav-list>
       
      </mat-menu>
      <button mat-icon-button [matMenuTriggerFor]="profileMenuDesktop" class="desktop-menu-button" *ngIf="auth.user$ | async as user" [@fadeIn]="fadeIn">
        <mat-icon>menu</mat-icon>
        Menu
      </button>

      <!-- Hamburger Menu for Mobile -->
      <button mat-icon-button [matMenuTriggerFor]="mobileMenu" class="mobile-menu-button" [@fadeIn]="fadeIn">
        <mat-icon>menu</mat-icon>
      </button>
      <mat-menu #mobileMenu="matMenu" [@fadeIn]="fadeIn">
        <mat-nav-list *ngIf="(auth.isAuthenticated$ | async) === true">
          <!-- <a mat-list-item href="../#">Networks</a> -->
          <a mat-list-item [href]="portalURL + '/EventLanding'" class="custom-hover-style-menu">Events</a>
          <a mat-list-item [href]="portalURL + '/About.aspx'" class="custom-hover-style-menu">About</a>
          <a mat-list-item [href]="portalURL + '/FAQs.aspx'" class="custom-hover-style-menu">FAQs</a>
          <a mat-list-item [href]="portalURL + '/Contact.aspx'" class="custom-hover-style-menu">Contact</a>
          <a mat-list-item *ngIf="userType && userType != 'S'" routerLink="/resources" class="custom-hover-style-menu">Resources</a>
        
          <!-- My Account (Collapsible Sub Menu) -->
          <mat-expansion-panel *ngIf="(auth.isAuthenticated$ | async) === true" [expanded]="true" (click)="$event.stopPropagation()" [style]="{ 'background-color': 'transparent' }">
            <mat-expansion-panel-header>
              My Account
            </mat-expansion-panel-header>
            
            <a mat-list-item *ngIf="userType && userType != 'S'" [href]="portalURL + '/Employers/EmployerDetail.aspx'" class="custom-hover-style-menu">Edit Profile</a>
            <a mat-list-item *ngIf="userType && userType == 'S'" [href]="portalURL + '/SeasonalWorkers/SeasonalWorkerDetail.aspx'" class="custom-hover-style-menu">Edit Profile</a>
            <a mat-list-item [href]="portalURL + '/Bookmarks.aspx'" class="custom-hover-style-menu">My Bookmarks</a>
            <a mat-list-item *ngIf="userType && userType != 'S'" [href]="portalURL + '/Employers/JobOffers'" class="custom-hover-style-menu">My Job Offers</a>
            <a mat-list-item *ngIf="userType && userType == 'S'" [href]="portalURL + '/SeasonalWorkers/WorkerJobOffers'" class="custom-hover-style-menu">My Job Offers</a>
            <a mat-list-item *ngIf="userType && userType != 'S'" [href]="portalURL + '/Employers/Employeropenapplications'" class="custom-hover-style-menu">My Applications</a>
            <a mat-list-item *ngIf="userType && userType == 'S'" [href]="portalURL + '/SeasonalWorkers/SeasonalWorkerapplications'" class="custom-hover-style-menu">My Applications</a>
            <a mat-list-item  *ngIf="userType && userType != 'S'" routerLink="/"  class="custom-hover-style-menu">Account Management</a>
           <a mat-list-item (click)="logout()"  class="custom-hover-style-menu">Logoff</a>
          </mat-expansion-panel>
        
          <!-- Social Icons and Close Menu -->
          <div class="menu-footer">
            <div class="social-icons">
              <a href="https://www.facebook.com/seasonalconnect" target="_blank">
                <mat-icon class="social-icon">facebook</mat-icon>
              </a>
              <a href="https://www.linkedin.com/company/seasonalconnect" target="_blank">
                <img src="assets/linkedin.svg" alt="LinkedIn" class="social-icon linkedin" />
              </a>
            </div>
            <span class="btn-close-menu">Close Menu</span>
          </div>
        </mat-nav-list>
      </mat-menu>
    </ng-container>

    <!-- Login on the Right -->
    <ng-container *ngIf="(auth.isAuthenticated$ | async) === false" >
      <div [@fadeIn]="fadeIn">
      <button mat-raised-button (click)="loginWithRedirect()">Log in</button>
    </div>
    </ng-container>
  </div>
</mat-toolbar>
