import { Component, Input, ViewEncapsulation } from '@angular/core';
import { NgbSlideEvent } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-photo-header',
  templateUrl: './photo-header.component.html',
  styleUrls: [
    './../../../../assets/css/bootstrap5.2.3.min.css', 
    './photo-header.component.css'
  ],
  encapsulation: ViewEncapsulation.ShadowDom  // Enable Shadow DOM encapsulation
})
export class PhotoHeaderComponent {
  @Input() slides: string[] = [];
  @Input() avatarUrl: string = '';
  @Input() employerName: string = '';
  @Input() tagline: string = '';

  showArrows = true;
  showIndicators = true;

}

