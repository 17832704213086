import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, switchMap, take, map, filter } from 'rxjs';
import { JobPosition } from '../models/JobModels';
import { ApiService } from '../api.service';

@Injectable({
  providedIn: 'root',
})
export class JobService {
  private jobsSubject = new BehaviorSubject<JobPosition[]>([]);
  private activeEmployerId: number | null = null;
  seasons: string[] = [];

  constructor(private apiService: ApiService) {
    this.activeEmployerId = Number.parseInt(sessionStorage.getItem('ActiveEmployerId') || '0', 10);
    this.loadJobs().subscribe(); // Initially load jobs
  }

  private loadJobs(): Observable<JobPosition[]> {
    if (!this.activeEmployerId) {
      return of([]);
    }

    return this.apiService.getPositionsForEmployer(this.activeEmployerId).pipe(
      take(1),
      map(jobs => {
        this.jobsSubject.next(jobs);
        return jobs;
      })
    );
  }

  refreshJobs(): Observable<JobPosition[]> {
    return this.loadJobs();
  }

  fetchJobs(): Observable<JobPosition[]> {
    const sessionEmployerId = Number.parseInt(sessionStorage.getItem('ActiveEmployerId') || '0', 10);
    if (this.activeEmployerId !== sessionEmployerId) {
      this.activeEmployerId = sessionEmployerId;
      this.loadJobs().subscribe();
    }
    return this.jobsSubject.asObservable();
  }

  getJobById(id: number): Observable<JobPosition | undefined> {
    return this.jobsSubject.asObservable().pipe(
      take(1),
      switchMap(jobs => {
        const job = jobs.find(job => job.employerPositionId === id);
        if (job) {
          return of(job);
        } else {
          // Job not found, reload explicitly and retry once more
          return this.loadJobs().pipe(
            map(newJobs => newJobs.find(job => job.employerPositionId === id))
          );
        }
      })
    );
  }
}
