<div class="container" *ngIf="isLoading">
    <app-loading></app-loading>
  </div>
  <form *ngIf="!isLoading" [formGroup]="paymentForm">
    <mat-card class="profile-data-mat-card">
      <div class="">
        <h2 class="center-title">Change subscription</h2>
          <div class="plan-selection">
        <mat-card-content >
            
          <mat-card-title>Please select subscription</mat-card-title>
  
          <div class="toggle-button">
            <span>Monthly </span>
            <mat-slide-toggle formControlName="yearly" color="accent"
              (change)="yearlyToggleTriggered()"></mat-slide-toggle>
            <span> Yearly </span>
          </div>
          <div fxLayout="row" fxLayoutGap="10px" fxLayoutGap.lt-sm="0px">
            <mat-form-field appearance="outline">
              <mat-label>Plan</mat-label>
              <mat-select formControlName="plan" (selectionChange)="planChangeTrigered()">
                <mat-option *ngFor="let plan of plans" [value]="plan.value">
                  {{plan.viewValue}} <!-- - ${{plan.price}} -->
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
  
          <!-- Amount and Coupon -->
          <!-- <div fxLayout="row" fxLayoutGap="10px">
            <mat-form-field appearance="outline" fxFlex="75">
              <mat-label>Coupon Code</mat-label>
              <input matInput formControlName="couponCode" placeholder="Coupon Code" (keydown.enter)="calculatePrice()">
            </mat-form-field>
            <button mat-raised-button type="button" class="apply-coupon-button" fxFlex="25"
              (click)="calculatePrice()">Apply</button>
          </div> -->
          <div fxLayout="row" fxLayoutGap="10px">
            <strong>Base Price:</strong>
            <div *ngIf="!isPriceLoading">{{ planPriceResponse.basePrice | currency:'USD':'symbol':'.2-2' }}</div>
            <mat-spinner *ngIf="isPriceLoading" [diameter]="15"></mat-spinner>
          </div>
  <p>Amounts will be calculated, prorated, and charged by our subscription service provider, ChargeOver. A receipt will be emailed to you for your records.</p>
          <br>
        
        </mat-card-content>
        <mat-card-actions>
            <button mat-raised-button class="submit-button"
            [routerLink]="['/']">Cancel</button>
          <button mat-raised-button class="submit-button" color="primary" [disabled]="paymentForm.invalid"
            (click)="openConfirmationDialog()">Submit</button>
        </mat-card-actions>
      </div>
    </div>
    <div class="expansion-panel" fxLayout="row" fxLayoutGap="10px" fxLayoutGap.lt-sm="0px">

        <mat-expansion-panel class="expansion-panel" [expanded]="true">
            <mat-expansion-panel-header>
              <mat-panel-title>
                Notes
              </mat-panel-title>
            </mat-expansion-panel-header>  
                       
            <p>  When you upgrade your subscription to a higher tier, you will be required to pay the difference between your current tier’s monthly cost and the cost of the tier you are upgrading to.  Once payment is remitted, you will have immediate access to your new tier and all of the additional features that come with it.</p>
    
            <p>  When you downgrade your subscription to a lower tier, this change will take place at the beginning of your next billing cycle.  The cost of your subscription will be adjusted to the cost of the lower tier at the beginning of your next billing cycle.</p>
            
          </mat-expansion-panel>
          </div>
    </mat-card>
  </form>