import { Component, Inject, Input } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { from } from 'rxjs';
import { ApiService } from 'src/app/api.service';
import { trigger, transition, useAnimation } from '@angular/animations';
import { bounceIn, fadeIn } from 'ng-animate';

@Component({
  selector: 'app-terms-of-service',
  templateUrl: './terms-of-service.component.html',
  styleUrls: ['./terms-of-service.component.css'],
  animations: [ trigger('bounceIn', [transition('* => *', useAnimation(bounceIn))]),trigger('fadeIn', [transition('* => *', useAnimation(fadeIn))])],

})
export class TermsOfServiceComponent {
  @Input() pageName: string; 
  
  safeContent: SafeHtml;
  content: string;
  fadeIn: any;
  
  constructor(public dialogRef: MatDialogRef<TermsOfServiceComponent>,
    private apiService: ApiService,
    private sanitizer: DomSanitizer,
    @Inject(MAT_DIALOG_DATA) public data: any 
    ) {}
  closeDialog() {
    this.dialogRef.close();
}

ngOnInit() {
  const pageToLoad = this.data?.pageName || 'Terms Of Service';
  this.apiService.GetPageByName(pageToLoad).subscribe(response => {
    this.content = response.content;
    this.safeContent = this.sanitizer.bypassSecurityTrustHtml(this.content);

  });
}

  acceptTerms() {
    this.dialogRef.close(true); // Close the dialog and return true
  }
}
