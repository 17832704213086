import { Component, OnInit, OnDestroy } from '@angular/core';
import { AuthService, User } from '@auth0/auth0-angular';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '../../api.service';
import { SharedDataService } from '../../shared-data-service.service';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { EMPTY, Observable, Subject, catchError, filter, from, map, startWith, switchMap, take, takeUntil } from 'rxjs';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ErrorModalComponent } from '../../error-modal/error-modal.component';

import { EmployerContactSubscriptionDTO, ChangePlanRequest, AddHousingPartnerContactLog, HousingPartner } from 'src/app/models/AccountManagementModels';
import { InfoModalComponent } from 'src/app/info-modal/info-modal.component';


@Component({
  selector: 'app-housing-contact-form',
  templateUrl: './housing-contact-form.component.html',
  styleUrls: ['./housing-contact-form.component.css'],
})
export class HousingContactFormComponent { unsubscribe$ = new Subject<void>();
  employerContactSubscription: EmployerContactSubscriptionDTO;
  requestForm: FormGroup;
  isLoading = true;
  minDate: Date;
  partnerId: number;
  partners: HousingPartner[] = [];
  isMessageSent = false;

  isPersonalInfoLoading: boolean = false;
  employerContactId: number;
  userType: string;
  portalURL: string;
  housingPartner: HousingPartner;

  constructor(
    public auth: AuthService,
    private sharedDataService: SharedDataService,
    private apiService: ApiService,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private router: Router,
    private dialog: MatDialog
  ) {

    const today = new Date();
    this.minDate = new Date(today.getFullYear(), today.getMonth(), today.getDate());

  }

  ngOnInit(): void {
    this.initializeForm();
    this.loadPartners();

    this.isLoading = true;
    this.route.queryParams.subscribe(params => {
      this.partnerId = params['PartnerId'];
      if(this.partnerId) {
        this.loadHousingPartner(this.partnerId);
      }
    });

    this.auth.user$
      .pipe(
        // Remove null or undefined values
        filter(user => !!user && !!user.nickname),
        // Get the primary employer for the contact
        switchMap(user => {
          const arrMeta = user.nickname.split(':');
          this.employerContactId = Number.parseInt(arrMeta[1]);
          this.userType = arrMeta[0];
          this.portalURL = this.sharedDataService.getData('portalURL');
          if (this.userType === 'E') {
            this.isPersonalInfoLoading = true;
            return this.apiService.getPrimaryEmployerForContact(this.employerContactId);
          }
          
          return EMPTY;
        }),

        // Handle errors in the pipeline
        catchError(e => {
          console.error(e);
          this.openErrorModal(e);
          this.router.navigate(['/error']);
          return EMPTY;
        }),

        // Unsubscribe when component is destroyed
        takeUntil(this.unsubscribe$)
      ).subscribe(employerContactSubscription => {
        this.employerContactSubscription = employerContactSubscription;
        const acceptTerms = this.employerContactSubscription.employer.acceptTerms;
        const acceptSubscription = this.employerContactSubscription.employer.acceptSubscription;
        // Check if either date is null or older than the SC open date
        if ((acceptTerms === null || new Date(acceptTerms) < new Date('2021-01-01')) ||
          (acceptSubscription === null || new Date(acceptSubscription) < new Date('2021-01-01'))) {
          this.router.navigate(['/accept-terms']);
          return;
        }

        this.isPersonalInfoLoading = false;
        this.setInitialData();

      });

  }

  initializeForm(): void {
    this.requestForm = this.fb.group({
      partnerId: ['', Validators.required],
      contactInfo: this.fb.group({
        firstName: ['', Validators.required],
        lastName: ['', Validators.required],
        email: ['', [Validators.required, Validators.email]],
        phone: ['']
      }),
      propertyInfo: this.fb.group({
        propertyName: ['', Validators.required],
        address: ['', Validators.required],
        city: ['', Validators.required],
        state: ['', Validators.required],
        zip: ['', [Validators.required, Validators.pattern('^[0-9]{5}(?:-[0-9]{4})?$')]]
      }),
      housingNeeds: this.fb.group({
        start: [null],
        end: [null],
        employees: [null],
        budget: [null],
        provider: [null],
        description: ['', Validators.required]
      })
    });

    this.requestForm.get('partnerId').valueChanges.subscribe((selectedPartnerId: number) => {
      this.onPartnerChange(selectedPartnerId);
    });
  }

  loadPartners(): void {
    this.apiService.getActivePartners().subscribe({
      next: (partners) => {
        this.partners = partners;
        this.requestForm.get('partnerId').setValue(this.partnerId);
        this.partnerId = this.partnerId;

        if (this.partnerId && this.partners.find(p => p.id == this.partnerId)) {
          this.housingPartner = this.partners.find(p => p.id == this.partnerId)
          this.requestForm.get('partnerId').setValue(this.housingPartner.id);

        } else if (this.partners.length > 0) {
          // Set default to the first partner if partnerId is invalid or not set
          this.requestForm.get('partnerId').setValue(this.partners[0].id);
        }
        this.isLoading = false;
      },
      error: (e) => {
        this.isLoading = false;
        console.error(e);
        this.openErrorModal(e);
      }
    });
  }
  onSubmit() {
    if (this.requestForm.valid) {
      this.isLoading = true;
      const formValue: AddHousingPartnerContactLog = this.requestForm.value;
      formValue.housingPartnerId = this.partnerId;
      formValue.employerContactId = this.employerContactSubscription.contact.employerContactId;
      formValue.employerId = this.employerContactSubscription.employer.employerId;
      formValue.housingNeeds.start = this.extractDateString(this.requestForm.get('housingNeeds').get('start').value);
      formValue.housingNeeds.end = this.extractDateString(this.requestForm.get('housingNeeds').get('end').value);
      this.apiService.addHousingPartnerContactLog(formValue)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe({
          next: (result) => {
            this.isLoading = false;
            this.openInfoModal("Your request has been submitted. Our partner will reach back out to you soon with a response to your request.");
            this.isMessageSent = true;

          },
          error: (e) => {
            this.isLoading = false;
            this.openErrorModal(e);
          }
        });
    }
    else{
      this.requestForm.markAllAsTouched();
      return;
    }
  }

  loadHousingPartner(partnerId: number): void {
    this.isLoading = true;
    this.apiService.getHousingPartner(partnerId).pipe(
      catchError(e => {
        this.isLoading = false;
        console.error(e);
        this.openErrorModal(e);
        return EMPTY;
      })
    ).subscribe({
      next: (res) => {
        this.housingPartner = res;
        this.isLoading = false;
      },
      error: (e) => {
        this.isLoading = false;
        this.requestForm.get('partnerId').setValue(this.partners[0].id);
        this.housingPartner = this.partners.find(p => p.id == this.partners[0].id)

        console.error(e);
        this.openErrorModal(e);
      }
    });
  }

  onCancel() {
    window.location.href = this.portalURL + "/Realtors/PropertySearch";

  }


  setInitialData() {

      // Setting contact information
      this.requestForm.get('contactInfo.firstName')?.setValue(this.employerContactSubscription.contact.contactFirstName);
      this.requestForm.get('contactInfo.lastName')?.setValue(this.employerContactSubscription.contact.contactLastName);
      this.requestForm.get('contactInfo.email')?.setValue(this.employerContactSubscription.contact.email);
      this.requestForm.get('contactInfo.phone')?.setValue(this.employerContactSubscription.contact.phone);

      // Setting property information
      this.requestForm.get('propertyInfo.propertyName')?.setValue(this.employerContactSubscription.employer.dba);
      this.requestForm.get('propertyInfo.address')?.setValue(this.employerContactSubscription.employer.address);
      this.requestForm.get('propertyInfo.city')?.setValue(this.employerContactSubscription.employer.city);
      this.requestForm.get('propertyInfo.zip')?.setValue(this.employerContactSubscription.employer.zip);

      this.apiService.GetStateName(Number.parseInt(this.employerContactSubscription.employer.state)).subscribe(stateName => {
        this.requestForm.get('propertyInfo.state')?.setValue(stateName.content);
      });

  }

  onPartnerChange(selectedPartnerId: number): void {
    const selectedPartner = this.partners.find(partner => partner.id === selectedPartnerId);
    if (selectedPartner) {
      this.housingPartner = selectedPartner;
      this.partnerId = selectedPartnerId;
      this.isMessageSent = false;
      // Update any other necessary fields or state
    }
  }

  openErrorModal(error: any): void {
    const dialogRef = this.dialog.open(ErrorModalComponent, {
      data: error,
      maxWidth: '70vw', // specify max width
      maxHeight: '80vh', // specify max height
      //height: '80vh' // specify height
    });

    // Optionally, you can subscribe to the afterClosed observable to perform actions after the modal is closed.
    dialogRef.afterClosed().subscribe(() => {
      // Perform any actions after the modal is closed (if needed).
    });
  }


  openInfoModal(message: string): void {
    const dialogRef = this.dialog.open(InfoModalComponent, {
      data: { message: message },
      maxWidth: '70vw', 
      maxHeight: '80vh',
    });   
  
    dialogRef.afterClosed().subscribe(() => {
    });
  }

  private extractDateString(date: Date): string {
    if(!date) {
      return null;
    }
    const year = date.getFullYear();
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const day = ('0' + date.getDate()).slice(-2);
    return `${month} / ${day} / ${year} `;
  }
  

}
