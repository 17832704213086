import { DatePipe } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { ApiService } from 'src/app/api.service';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { trigger, transition, useAnimation } from '@angular/animations';
import { bounceIn, fadeIn } from 'ng-animate';


@Component({
  selector: 'app-platform-subscription-agreement',
  templateUrl: './platform-subscription-agreement.component.html',
  styleUrls: ['./platform-subscription-agreement.component.css'],
  providers: [DatePipe],
  animations: [ trigger('bounceIn', [transition('* => *', useAnimation(bounceIn))]),trigger('fadeIn', [transition('* => *', useAnimation(fadeIn))])],

})

export class PlatformSubscriptionAgreementComponent {
  myDate = new Date();
  currentDateTime: string;
  safeContent: SafeHtml;
  content: string;
  isButtonDisabled = true;
  signupForm: FormGroup; // assuming the form type is FormGroup
  stateCtrl: string;
  fadeIn: any;
  constructor(
    public dialogRef: MatDialogRef<PlatformSubscriptionAgreementComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,  // Injecting data here
    private apiService: ApiService,
    private datePipe: DatePipe,
    private sanitizer: DomSanitizer,
  ) {
    this.signupForm = data.signupForm;
    this.stateCtrl = data.stateCtrl;  // Assigning stateCtrl from passed data
    this.currentDateTime = this.datePipe.transform(this.myDate, 'MM/dd/YYYY');
  }

  ngOnInit() {
    this.apiService.GetPageByName('Platform Subscription Agreement').subscribe(response => {
      this.content = response.content;
      this.replacePlaceholders();
    });
  }

  replacePlaceholders() {
    const firstName = this.signupForm?.get('section2')?.get('firstName')?.value;
    const lastName = this.signupForm?.get('section2')?.get('lastName')?.value;
    const companyName = this.signupForm?.get('section2')?.get('companyName')?.value;
    const city = this.signupForm?.get('section2')?.get('city')?.value;
    const state = this.stateCtrl;
    this.content = this.content?.replace('{{ nameOfContact }}', `${firstName} ${lastName}`);
    this.content = this.content?.replace('{{ nameOfContact }}', `${firstName} ${lastName}`);
    this.content = this.content?.replace('{{ date }}', this.currentDateTime);
    this.content = this.content?.replace('{{ organisationName }}', companyName);
    this.content = this.content?.replace('{{ cityState }}', `${city}, ${state}`);

    this.safeContent = this.sanitizer.bypassSecurityTrustHtml(this.content);
    // Check for presence of all values to decide whether to enable the button
    this.isButtonDisabled = !(firstName && lastName && companyName && city && state);
  }

  closeDialog() {
    this.dialogRef.close();
  }

  acceptTerms() {
    this.dialogRef.close(true); // Close the dialog and return true
  }
}