import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-reject-reason-dialog',
  template: `
    <h1 mat-dialog-title>Select a Rejection Reason</h1>
    <div mat-dialog-content>
      <mat-form-field appearance="outline">
        <mat-label>Reason</mat-label>
        <mat-select [(value)]="selectedReason">
          <mat-option *ngFor="let reason of data.reasons" [value]="reason">
            {{ reason }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div mat-dialog-actions>
      <button mat-button (click)="onCancel()">Cancel</button>
      <button mat-button (click)="onConfirm()" [disabled]="!selectedReason">Confirm</button>
    </div>
  `
})
export class RejectReasonDialogComponent {
  selectedReason: string;
  constructor(
    public dialogRef: MatDialogRef<RejectReasonDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { reasons: string[] }
  ) {}

  onConfirm(): void {
    this.dialogRef.close(this.selectedReason);
  }

  onCancel(): void {
    this.dialogRef.close();
  }
}
