import { Component } from '@angular/core';
import { SharedDataService } from '../shared-data-service.service';

@Component({
  selector: 'app-signup-done',
  templateUrl: './signup-done.component.html',
  styleUrls: ['./signup-done.component.css']
})
export class SignupDoneComponent {
  employerData: any;
  constructor(
    private sharedDataService: SharedDataService,

  ) {
    
  }
  ngOnInit(): void {
    this.employerData = this.sharedDataService.getData('employer');
    this.sharedDataService.clearStorage();
  }
}
