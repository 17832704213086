<h2 mat-dialog-title>Filter Applicants</h2>
<mat-dialog-content>
  <mat-form-field appearance="outline">
    <mat-label>Position</mat-label>
    <mat-select [(ngModel)]="filterValue.position">
      <mat-option value="">All</mat-option>
      <!-- Optionally, populate options dynamically if needed -->
    </mat-select>
  </mat-form-field>

  <mat-form-field appearance="outline">
    <mat-label>Available Start Date</mat-label>
    <input matInput [matDatepicker]="picker" [(ngModel)]="filterValue.startDate">
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-datepicker #picker></mat-datepicker>
  </mat-form-field>

  <mat-checkbox [(ngModel)]="filterValue.onlyWithResume">
    Only show applicants with a résumé
  </mat-checkbox>
</mat-dialog-content>

<mat-dialog-actions>
  <button mat-button (click)="apply()">Apply</button>
  <button mat-button (click)="close()">Close</button>
</mat-dialog-actions>
