<div class="container" *ngIf="isLoading">
    <app-loading></app-loading>
  </div>
  <form *ngIf="!isLoading" >
    <mat-card class="profile-data-mat-card">
      <div class="">
        <h2 class="center-title">Order Contacts</h2>
          <div class="plan-selection">
            <mat-card-content *ngIf="employerContactSubscription">
                <div *ngIf="this.employerContactSubscription.contactPermissions.employerAdmin == false">
                    <p> Please note, your plan allows for <span *ngIf="adminLimit"> {{ adminLimit }}</span> user<span *ngIf="adminLimit && adminLimit > 1">s</span> to be active with your subscription.  Please contact your account administrator to upgrade your plan or to change the users that are active on your account.</p>
                </div>
                <div *ngIf="this.employerContactSubscription.contactPermissions.employerAdmin == true">
        <div class="container" *ngIf="isLoadingOrder">
                <app-loading class="order-loading"></app-loading>
              </div>
              <div cdkDropList (cdkDropListDropped)="drop($event)" *ngIf="!isLoadingOrder && contactsWithPermissions">
                <div *ngFor="let contactWithPermissions of contactsWithPermissions; let i = index" 
                     cdkDrag class="draggable-item" cdkDragLockAxis="y"
                     [ngClass]="{'admin-contact': i < adminLimit, 'normal-contact': i >= adminLimit}">
                    {{ contactWithPermissions.contact.contactFirstName }} {{ contactWithPermissions.contact.contactLastName }}
                    <span class="float-right"> | {{ getRole(contactWithPermissions.contactPermission?.employerAdmin) }} </span>
                </div>
              </div>
              

        </div>
        </mat-card-content>
      </div>
    </div>
    <mat-action-row *ngIf="this.employerContactSubscription.contactPermissions.employerAdmin == true">
        <button mat-raised-button (click)="submit()">Apply</button>
    </mat-action-row>
    <div class="expansion-panel" fxLayout="row" fxLayoutGap="10px" fxLayoutGap.lt-sm="0px">

        <mat-expansion-panel class="expansion-panel" [expanded]="true">
            <mat-expansion-panel-header>
              <mat-panel-title>
                Notes
              </mat-panel-title>
            </mat-expansion-panel-header>  
                       
            <p *ngIf="this.employerContactSubscription.plan.planId >= 3">  Please note, your plan allows for an unlimited number of users to be active with your subscription. </p>
    
            <p *ngIf="this.employerContactSubscription.plan.planId < 3">  Please note, your plan allows for <span *ngIf="adminLimit"> {{ adminLimit }}</span> user<span *ngIf="adminLimit && adminLimit > 1">s</span> to be active with your subscription. Drag and drop the <span *ngIf="adminLimit"> {{ adminLimit }}</span> user<span *ngIf="adminLimit && adminLimit > 1">s</span> you wish to be active to the top of the list.  All other users will be inactive unless you upgrade to a higher tier.</p>
            
          </mat-expansion-panel>
          </div>
    </mat-card>
  </form>


