import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-video-player',
  templateUrl: './video-player.component.html',
  styleUrls: ['./video-player.component.css']
})
export class VideoPlayerComponent implements OnInit {
  videoUrl: string;

  constructor(private route: ActivatedRoute, private router: Router) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.videoUrl = params['url'];
      this.handleVideoUrl(this.videoUrl);
    });
  }

  handleVideoUrl(url: string): void {
    if (this.isDirectVideoFile(url)) {
      // Do nothing, let the video element handle it
    } else {
      // Redirect to the URL
      window.location.href = url;
    }
  }

  isDirectVideoFile(url: string): boolean {
    const videoFilePattern = /\.(avi|mov|mpg|mpeg|mp4|wmv|webm|ogv)$/i;
    return videoFilePattern.test(url);
  }
}
