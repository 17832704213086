import { Component, OnInit, ViewChild } from '@angular/core';
import { AuthService, User } from '@auth0/auth0-angular';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { TermsOfServiceComponent } from '../terms-of-service/terms-of-service.component';
import { PlatformSubscriptionAgreementComponent } from '../platform-subscription-agreement/platform-subscription-agreement.component';
import { EMPTY, Observable, Subject, catchError, filter, map, of, startWith, switchMap, takeUntil } from 'rxjs';
import { trigger, state, style, animate, transition, useAnimation } from '@angular/animations';
import { ApiService } from 'src/app/api.service';
import { CreateWorkerModel, Employer } from '../models/SignupModels';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { AuthHttpInterceptor } from '@auth0/auth0-angular';
import { SharedDataService } from '../shared-data-service.service';
import { ErrorModalComponent } from '../error-modal/error-modal.component';
import { MatStepper } from '@angular/material/stepper';
import { bounceIn, fadeIn,slideOutDown } from 'ng-animate';
import { Country, CountryService } from '../services/CountryService.service';
import { ConfirmationCodeDialogComponent } from '../confirmation-code-dialog/confirmation-code-dialog.component';


@Component({
  selector: 'app-signup-worker',
  templateUrl: './signup-worker.component.html',
  styleUrls: ['./signup-worker.component.css'],
  animations: [
    trigger('expandCollapse', [
      state('collapsed', style({ height: '0px', minHeight: '0', marginBottom: '0' })),
      state('expanded', style({ height: '*', marginBottom: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4,0.0,0.2,1)')),
    ]),
    trigger('slideOutDown', [transition('* => *', useAnimation(slideOutDown))]),
    trigger('fadeIn', [transition(':enter', useAnimation(fadeIn)), transition(':leave', [])], ),
  ],

})

export class SignupWorkerComponent implements OnInit {
  @ViewChild('stepper') public stepper: MatStepper;
  private unsubscribe$ = new Subject<void>();
  public showPassword: boolean = false;
  isLoading = false;
  isConfirmationCodeSent = false;
  panelOpenState = false; 


  slideOutDown: any;
  fadeIn: any;

  countries: Country[] = [];
  showVisaInfo: boolean = false;
  minDate: Date;

  userType: string;
  portalURL: string;

  states: string[] = ['Alabama', 'Alaska', 'Arizona', 'Arkansas', 'California', 'Colorado', 'Connecticut', 'Delaware', 'Florida', 'Georgia', 'Hawaii', 'Idaho', 'Illinois', 'Indiana', 'Iowa', 'Kansas', 'Kentucky', 'Louisiana', 'Maine', 'Maryland', 'Massachusetts', 'Michigan', 'Minnesota', 'Mississippi', 'Missouri', 'Montana', 'Nebraska', 'Nevada', 'New Hampshire', 'New Jersey', 'New Mexico', 'New York', 'North Carolina', 'North Dakota', 'Ohio', 'Oklahoma', 'Oregon', 'Pennsylvania', 'Rhode Island', 'South Carolina', 'South Dakota', 'Tennessee', 'Texas', 'Utah', 'Vermont', 'Virginia', 'Washington', 'West Virginia', 'Wisconsin', 'Wyoming'];
  stateCtrl = new FormControl('');
  filteredStates: Observable<string[]>;

  signupForm: FormGroup;
   // Form controls for each country field
   birthCountryCtrl = new FormControl('', [Validators.required, (fc: FormControl) => this.countryValidator(fc)]);
   citizenOfCountryCtrl = new FormControl('', [Validators.required, (fc: FormControl) => this.countryValidator(fc)]);
   currentCountryCtrl = new FormControl('', [Validators.required, (fc: FormControl) => this.countryValidator(fc)]);
   // Observable arrays for filtered countries
   filteredBirthCountries: Country[];
   filteredCitizenCountries: Country[];
   filteredCurrentCountries: Country[];

  constructor(private formBuilder: FormBuilder, 
    public dialog: MatDialog, 
    private apiService: ApiService, 
    private router: Router,
    private sharedDataService: SharedDataService, 
    private route: ActivatedRoute,
    private countryService: CountryService,
    public auth: AuthService,) {

      this.route.queryParams.subscribe(params => {

        this.signupForm = this.formBuilder.group({
          emailPassSection: this.formBuilder.group({
            email: ['', [Validators.required, Validators.email]],
            password: ['', [Validators.required, this.passwordValidator]],
          }),
          nameSection: this.formBuilder.group({
            firstName: ['', Validators.required],
            lastName: ['', Validators.required],

          }),
          termsSection: this.formBuilder.group({
            confirmationCode: ['', [Validators.required, Validators.minLength(5), Validators.maxLength(5)]],
            termsOfService: [false, Validators.requiredTrue],
            privacyPolicy: [false],

          }),
          currentAddress: this.formBuilder.group({
            currentCity: ['', Validators.required],
            willingToRelocate: ['', Validators.required],
            
          }),
          visaInfo: this.formBuilder.group({
            sponsorshipRequired: ['', Validators.required],
            ownVisa: ['', Validators.required],
            sponsorshipSeeking: ['', ],
            currentVisa: ['', ],
            currentVisaExpiryDate: [null], 
            h2bThreeYearLimit: [null], 
          }),
        });
      });

      const today = new Date();
      this.minDate = new Date(today.getFullYear(), today.getMonth(), today.getDate());
  }

  ngOnInit(): void {

    this.auth.user$
    .pipe(
      // Remove null or undefined values
      filter(user => !!user && !!user.nickname),

      // Get the primary employer for the contact
      switchMap(user => {
        const arrMeta = user.nickname.split(':');
        const employerContactId = Number.parseInt(arrMeta[1]);
        this.userType = arrMeta[0];
        this.portalURL = this.sharedDataService.getData('portalURL');
        if (this.userType === 'S') {
          if(!this.portalURL)    {
            this.apiService.GetConfigurationParam("PortalURL").subscribe(res => { 
              this.portalURL = res; 
              this.sharedDataService.setData<string>('portalURL', res);
              window.location.href = this.portalURL;
            });
          }
          else{
            window.location.href = this.portalURL;
          }
        }
        return EMPTY;
      }),

      // Handle errors in the pipeline
      catchError(e => {
        console.error(e);
        this.openErrorModal(e);
        this.router.navigate(['/error']);
        return EMPTY;
      }),

      // Unsubscribe when component is destroyed
      takeUntil(this.unsubscribe$)
    ).subscribe();

    this.countryService.getAllCountries()
    .subscribe((data: Country[]) => {
      this.countries = data;
      this.filteredBirthCountries = data;
      this.filteredCitizenCountries = data;
      this.filteredCurrentCountries = data;
    });


      this.setupFilters();
      this.setupValidators();
  }
  setupFilters()
  {
    this.birthCountryCtrl.valueChanges
      .pipe(
        startWith(''),
        map(value => this._filterCountries(value || ''))
      ).subscribe(data => {
        this.filteredBirthCountries = data
      });

    this.citizenOfCountryCtrl.valueChanges
      .pipe(
        startWith(''),
        map(value => this._filterCountries(value || ''))
      ).subscribe(data => {
        this.filteredCitizenCountries = data
      });

    this.currentCountryCtrl.valueChanges
      .pipe(
        startWith(''),
        map(value => this._filterCountries(value || ''))
      ).subscribe(data => {
        this.filteredCurrentCountries = data
      });
  }

  setupValidators() {

    this.signupForm.get('visaInfo.sponsorshipRequired').valueChanges.subscribe(value => {
      const sponsorshipSeekingControl = this.signupForm.get('visaInfo.sponsorshipSeeking');
      if (value === 'true') { // Assuming the value 'true' as a string here
        sponsorshipSeekingControl.setValidators(Validators.required);
      } else {
        sponsorshipSeekingControl.clearValidators();
        this.clearDateTimeRequired();
      }
      sponsorshipSeekingControl.updateValueAndValidity();
    });

    this.signupForm.get('visaInfo.ownVisa').valueChanges.subscribe(value => {
      const sponsorshipSeekingControl = this.signupForm.get('visaInfo.currentVisa');
      if (value === 'true') { // Assuming the value 'true' as a string here
        sponsorshipSeekingControl.setValidators(Validators.required);
      } else {
        sponsorshipSeekingControl.clearValidators();
        this.clearDateTimeRequired();
      }
      sponsorshipSeekingControl.updateValueAndValidity();
    });

    this.signupForm.get('visaInfo.currentVisa').valueChanges.subscribe(currentVisa => {
      const currentVisaExpiryDateControl = this.signupForm.get('visaInfo.currentVisaExpiryDate');
      const h2bThreeYearLimitControl = this.signupForm.get('visaInfo.h2bThreeYearLimit');
      const ownVisa =  this.signupForm.get('visaInfo.ownVisa').value;

      if (currentVisa === 'H2B' && this.showVisaInfo && ownVisa === 'true') {
        h2bThreeYearLimitControl.setValidators(Validators.required);
        h2bThreeYearLimitControl.updateValueAndValidity();
        currentVisaExpiryDateControl.setValidators(Validators.required);
        currentVisaExpiryDateControl.updateValueAndValidity();
      } else {
        h2bThreeYearLimitControl.clearValidators();
        h2bThreeYearLimitControl.updateValueAndValidity();
      }

      if((currentVisa === 'J1' || currentVisa === 'H2B') && this.showVisaInfo && ownVisa === 'true') {
        currentVisaExpiryDateControl.setValidators(Validators.required);
        currentVisaExpiryDateControl.updateValueAndValidity();
      }
      else{
        currentVisaExpiryDateControl.clearValidators();
        currentVisaExpiryDateControl.updateValueAndValidity();
      }
    });

    this.currentCountryCtrl.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe(selectedCountry => {
      if (selectedCountry !== 'United States of America') {
        // Clear validators and reset the value if the selected country is not USA
        this.stateCtrl.clearValidators();
        this.stateCtrl.reset();
        this.filteredStates = of([]);
      } else {
        // Reinstate validators if USA is selected
        this.stateCtrl.setValidators([Validators.required, (fc: FormControl) => this.stateValidator(fc)]);
        this.filteredStates = this.stateCtrl.valueChanges
          .pipe(
            startWith(''),
            map(state => this._filterStates(state || '')),
          );
      }
  
      this.stateCtrl.updateValueAndValidity();
    });

    this.citizenOfCountryCtrl.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe(selectedCountry => {
      if (selectedCountry === 'United States of America') {
        this.showVisaInfo = false;
        this.clearDateTimeRequired();

        this.signupForm.get('visaInfo').disable();
      } else {
        this.showVisaInfo = true;
        this.signupForm.get('visaInfo').enable();
      }
    });

    this.signupForm.get('emailPassSection.email').valueChanges.subscribe(value => {
      this.isConfirmationCodeSent = false;
    });  
  }

  clearDateTimeRequired() {
    const currentVisaExpiryDateControl = this.signupForm.get('visaInfo.currentVisaExpiryDate');
    const h2bThreeYearLimitControl = this.signupForm.get('visaInfo.h2bThreeYearLimit');

    currentVisaExpiryDateControl.clearValidators();
    currentVisaExpiryDateControl.updateValueAndValidity();
    h2bThreeYearLimitControl.clearValidators();
    h2bThreeYearLimitControl.updateValueAndValidity();
  }

  onSubmit(): void {
    this.sharedDataService.clearStorage();
    if (this.signupForm.invalid) {
      this.signupForm.markAllAsTouched(); // mark fields as touched to show errors

      this.stepper.selectedIndex = this.findFirstInvalidControlIndex();
      return;
    } else {   

      const createWorkerModel: CreateWorkerModel = {
        email: this.signupForm.get('emailPassSection').get('email').value,
        password: this.signupForm.get('emailPassSection').get('password').value,
        firstName:  this.signupForm.get('nameSection').get('firstName').value,
        lastName: this.signupForm.get('nameSection').get('lastName').value,
        city:this.signupForm.get('currentAddress').get('currentCity').value,
        state: this.stateCtrl.value,
        birthCountry: this.countries.find(country => country.CountryName === this.birthCountryCtrl.value)?.CountryCode,        
        currentCountry: this.countries.find(country => country.CountryName ===  this.currentCountryCtrl.value)?.CountryCode,
        citizenOfCountry: this.countries.find(country => country.CountryName ===  this.citizenOfCountryCtrl.value)?.CountryCode,
        sponsorshipRequired: this.signupForm.get('visaInfo').get('sponsorshipRequired').value === 'true',
        ownVisa: this.signupForm.get('visaInfo').get('ownVisa').value === 'true',
        sponsorshipSeeking: this.signupForm.get('visaInfo').get('sponsorshipSeeking').value ?? '',
        currentVisa: this.signupForm.get('visaInfo').get('currentVisa').value ?? '',
        currentVisaExpiryDate: this.convertToUTC(this.signupForm.get('visaInfo').get('currentVisaExpiryDate').value) ?? null,
        h2bThreeYearLimit: this.convertToUTC(this.signupForm.get('visaInfo').get('h2bThreeYearLimit').value) ?? null,
        willingToRelocate: this.signupForm.get('currentAddress').get('willingToRelocate').value === 'true',
        termsOfService: this.signupForm.get('termsSection').get('termsOfService').value,
        privacyPolicy: this.signupForm.get('termsSection').get('privacyPolicy').value,
        confirmationCode: this.signupForm.get('termsSection').get('confirmationCode').value ?? ''
      };

      console.log(createWorkerModel);

      this.isLoading = true;
      this.apiService.createWorker(createWorkerModel).subscribe(response => {
      //   createEmployerModel.employerId = response.employerId;
         this.isLoading = false;
         this.router.navigate(['/signup-worker-done']);
       }, error => {
         this.isLoading = false;
         this.openErrorModal(error);
       });
    }
  }

  goToNextStep(): void {
    this.signupForm.controls['emailPassSection'].markAllAsTouched();
    this.signupForm.controls['nameSection'].markAllAsTouched();
    this.signupForm.controls['currentAddress'].markAllAsTouched();
    this.currentCountryCtrl.markAllAsTouched();
    this.birthCountryCtrl.markAllAsTouched();
    this.citizenOfCountryCtrl.markAllAsTouched();
    this.stateCtrl.markAllAsTouched();

    if (this.stepper.selectedIndex === 0 && 
        !(
          this.signupForm.controls['emailPassSection'].valid && 
          this.signupForm.controls['nameSection'].valid &&
          this.signupForm.controls['currentAddress'].valid &&
          this.currentCountryCtrl.valid &&
          this.birthCountryCtrl.valid &&
          this.citizenOfCountryCtrl.valid &&
          this.stateCtrl.valid
        )
    ) { } 
    else {
      this.sendConfirmationCodeEmail();
      this.stepper.next();
    }
  }

  private findFirstInvalidControlIndex(): number {
    // Logic to find the first invalid section
    if (!this.signupForm.controls['emailPassSection'].valid) return 0;
    if (!this.signupForm.controls['nameSection'].valid) return 0;
    if (!this.currentCountryCtrl.valid) return 0;
    if (!this.birthCountryCtrl.valid) return 0;
    if (!this.citizenOfCountryCtrl.valid) return 0;
    if (!this.stateCtrl.valid) return 0;
    if (!this.signupForm.controls['currentAddress'].valid) return 0;
    if (!this.signupForm.controls['visaInfo'].valid) return 1;
    if (!this.signupForm.controls['termsSection'].valid) return 1;
    return 1; // Default to the second step if the first step is valid
  }

  private _filterStates(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.states.filter(state => state.toLowerCase().includes(filterValue));
  }

  private _filterCountries(value: string): Country[] {
    const filterValue = value.toLowerCase();
    return this.countries.filter(country => 
      country.CountryName.toLowerCase().includes(filterValue));
  }

  get isPasswordError(): boolean {
    return this.signupForm.get('emailPassSection').get('password').hasError('passwordValidator');
  }

  get isStateError(): boolean {
    return this.stateCtrl.hasError('stateValidator');  
  }  
 
  passwordValidator(formControl: FormControl) {
    const password = formControl.value;
    const hasNumber = /\d/.test(password);
    const hasLowercase = /[a-z]/.test(password);
    const hasUppercase = /[A-Z]/.test(password);
    const hasSpecial = /[!@#%^&$*()_+\-=[\]{}|;':"\\,.<>/?~`]/.test(password);
    const lengthValid = password.length >= 8 ? true : false;
    const valid = hasNumber && hasLowercase && hasUppercase && lengthValid && hasSpecial;
    if (!valid) {
      // return what´s not valid
      return { passwordValidator: true };
    }
    return null;
  }
  stateValidator(formControl: FormControl) {
    formControl.value
    const valid = this.states.includes(formControl.value);
    if (!valid) {
      // return what´s not valid
      return { stateValidator: true };
    }
    return null;
  }

  countryValidator(formControl: FormControl) {
    formControl.value
    const valid = this.countries.map(c => c.CountryName).includes(formControl.value);
    if (!valid) {
      // return what´s not valid
      return { value: true };
    }
    return null;
  }

  openTermsOfService(): void {
    const dialogRef = this.dialog.open(TermsOfServiceComponent, {
      maxWidth: '70vw',  // specify max width
      maxHeight: '80vh',  // specify max height
      height: '80vh',  // specify height
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        // Set the 'termsOfService' form control value to true
        this.signupForm.get('termsSection').get('termsOfService').setValue(true);
      }
    });
  }

  openPrivacyPolicy(): void {
    const dialogRef = this.dialog.open(TermsOfServiceComponent, {
      maxWidth: '70vw',  // specify max width
      maxHeight: '80vh',  // specify max height
      height: '80vh',  // specify height
      data: { pageName: 'Privacy Policy' }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        // Set the 'termsOfService' form control value to true
        this.signupForm.get('termsSection').get('privacyPolicy').setValue(true);
      }
    });
  }

  openConfirmationDialog(): void {
    const dialogRef = this.dialog.open(ConfirmationCodeDialogComponent, {
      data: {
        message: 'Do you want us to send you a new code? '
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.isConfirmationCodeSent = false;
        this.sendConfirmationCodeEmail();
        // Display your message that code has been sent
      }
    });
  }


  sendConfirmationCodeEmail() {
    if(!this.isConfirmationCodeSent)
    {
      this.apiService.sendConfirmationCodeEmail(this.signupForm.get('emailPassSection').get('email').value)
            .subscribe(() => {
            });

      this.isConfirmationCodeSent = true;
    }
    
  }

  clearInput(control: FormControl) {
    control.setValue('');
}


  openErrorModal(error: any): void {
    const dialogRef = this.dialog.open(ErrorModalComponent, {
      data: error,
      maxWidth: '70vw', // specify max width
      maxHeight: '80vh', // specify max height
      //height: '80vh' // specify height
    });

    // Optionally, you can subscribe to the afterClosed observable to perform actions after the modal is closed.
    dialogRef.afterClosed().subscribe(() => {
      // Perform any actions after the modal is closed (if needed).
    });
  }
  public togglePasswordVisibility(): void {
    this.showPassword = !this.showPassword;
  }  

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private convertToUTC(date: Date): Date {
    if(!date)
     {
      return null;
     }
    const utcDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
    return utcDate;
}
  
}