<div class="container" *ngIf="isLoading" [@fadeIn]="fadeIn">
    <app-loading></app-loading>
</div>
<form *ngIf="!isLoading" [formGroup]="signupForm" (ngSubmit)="onSubmit()">

    <mat-card class="">
        
        <div class="signup-worker-mat-card">
            <h1 class="center-title-join"><span class="center-title-span">Join the </span>Seasonal Connect Community
            </h1>
            <p class="center-paragraph">Seasonal Connect is a <b>completely free</b> service for workers to use the platform. Seasonal Connect
                will never ask any worker for money and we will never solicit money from seasonal workers.</p>
            <mat-card-content>
                <mat-horizontal-stepper #stepper [linear]="true">
                <mat-step [stepControl]="signupForm.controls['emailPassSection']" >
                <div formGroupName="emailPassSection">
                    <!-- Section 1: Email & Password -->
                    <mat-card-title>Email & Password</mat-card-title>
                    <div fxLayout="row" fxLayoutGap="10px" fxLayoutGap.lt-sm="0px">
                        <mat-form-field appearance="outline">
                            <mat-label>Email</mat-label>
                            <input outlined matInput placeholder="Email*" formControlName="email" maxlength="100">
                            <mat-error *ngIf="signupForm.get('emailPassSection').get('email').hasError('required')">
                                Please enter an email address. 
                            </mat-error>
                            <mat-error *ngIf="signupForm.get('emailPassSection').get('email').hasError('email')">
                                Please enter a valid email address.
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div fxLayout="row" fxLayoutGap="10px" fxLayoutGap.lt-sm="0px">
                        <mat-form-field appearance="outline" subscriptSizing="dynamic">
                            <mat-label>Password</mat-label>
                            <input matInput [type]="showPassword ? 'text' : 'password'" name="password"
                                formControlName="password" maxlength="100">
                            <mat-icon matSuffix (click)="togglePasswordVisibility()">
                                {{showPassword?'visibility_off':'visibility'}}
                            </mat-icon>
                            <mat-error *ngIf="signupForm.get('emailPassSection').get('password').hasError('required')">
                                Please enter an Password.
                            </mat-error>
                            <mat-error *ngIf="isPasswordError"
                                [@expandCollapse]="isPasswordError ? 'expanded' : 'collapsed'"
                                style="display: inline-block;">
                                Password must be at least 6 characters and include an uppercase letter, a lowercase
                                letter, a number and a special character - !&#64;#%^&$*_+-~.,
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>
                <!-- Section 2: Company Details -->
                <div formGroupName="nameSection" class="company-details">
                    <mat-card-title>Your Details</mat-card-title>

                    <div fxLayout="row" fxLayoutGap="10px" fxLayoutGap.lt-sm="0px">
                        <mat-form-field appearance="outline" fxFlex="50" subscriptSizing="dynamic">
                            <mat-label>First Name</mat-label>
                            <input matInput placeholder="First Name*" formControlName="firstName" maxlength="30">
                            <mat-error *ngIf="signupForm.get('nameSection').get('firstName').hasError('required')">
                                First Name is required
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="outline" fxFlex="50" subscriptSizing="dynamic">
                            <mat-label>Last Name</mat-label>
                            <input matInput placeholder="Last Name*" formControlName="lastName" maxlength="30">
                            <mat-error *ngIf="signupForm.get('nameSection').get('lastName').hasError('required')">
                                Last Name is required
                            </mat-error>
                        </mat-form-field>
                    </div>                   

                </div>
                
                <div fxLayout="row" fxLayoutGap="10px" fxLayoutGap.lt-sm="0px">
                        
                    <mat-form-field appearance="outline" fxFlex="100" subscriptSizing="dynamic">
                        <mat-label>Birth Country</mat-label>
                        <input type="text" placeholder="Birth Country" aria-label="Country" matInput 
                        [formControl]="birthCountryCtrl" [matAutocomplete]="autoBirth">

                        <mat-icon matSuffix *ngIf="birthCountryCtrl.value" (click)="clearInput(birthCountryCtrl)">close</mat-icon>

                        <mat-autocomplete #autoBirth="matAutocomplete">
                          <mat-option *ngFor="let country of filteredBirthCountries" [value]="country.CountryName">
                            {{ country.CountryName }}
                          </mat-option>
                        </mat-autocomplete>
                        <mat-error *ngIf="!this.birthCountryCtrl.valid" [@expandCollapse]="!this.birthCountryCtrl.valid ? 'expanded' : 'collapsed'"
                        style="display: inline-block;">
                        Please select a country from the dropdown menu.
                    </mat-error>
                      </mat-form-field>
                    </div>
                    <div fxLayout="row" fxLayoutGap="10px" fxLayoutGap.lt-sm="0px">
                        <mat-form-field appearance="outline" fxFlex="100" subscriptSizing="dynamic">
                            <mat-label>Citizen Of Country</mat-label>
                            <input type="text" placeholder="Citizen Of Country" aria-label="Country" matInput
                                   [formControl]="citizenOfCountryCtrl" [matAutocomplete]="autoCitizen">
                            
                                <mat-icon matSuffix *ngIf="citizenOfCountryCtrl.value" (click)="clearInput(citizenOfCountryCtrl)">close</mat-icon>
                        
                            <mat-autocomplete #autoCitizen="matAutocomplete">
                                <mat-option *ngFor="let country of filteredCitizenCountries" [value]="country.CountryName">
                                    {{country.CountryName}}
                                </mat-option>
                            </mat-autocomplete>
                            
                            <mat-error *ngIf="!this.citizenOfCountryCtrl.valid" [@expandCollapse]="!this.citizenOfCountryCtrl.valid ? 'expanded' : 'collapsed'"
                                       style="display: inline-block;">
                                Please select a country from the dropdown menu.
                            </mat-error>
                        </mat-form-field>
                        
                </div>
                
                 <div formGroupName="currentAddress" class="company-details">
                    <mat-card-title>Current Address</mat-card-title>
                    <div fxLayout="row" fxLayoutGap="10px" fxLayoutGap.lt-sm="0px">
                        <mat-form-field appearance="outline" fxFlex="100"  subscriptSizing="dynamic">
                            <mat-label>Country</mat-label>
                            <input type="text" placeholder="Country" aria-label="Country" matInput
                                [formControl]="currentCountryCtrl" [matAutocomplete]="autoCurrentCountry">

                                <mat-icon matSuffix *ngIf="currentCountryCtrl.value" (click)="clearInput(currentCountryCtrl)">close</mat-icon>

                            <mat-autocomplete #autoCurrentCountry="matAutocomplete">
                                <mat-option *ngFor="let country of filteredCurrentCountries" [value]="country.CountryName">
                                    {{country.CountryName}}
                                </mat-option>
                            </mat-autocomplete>
                            <mat-error *ngIf="!this.currentCountryCtrl.valid"   [@expandCollapse]="!this.currentCountryCtrl.valid ? 'expanded' : 'collapsed'"
                                style="display: inline-block;">
                                Please select a country from the dropdown menu.
                            </mat-error>
                        </mat-form-field>
                        
                    </div>
                    <div fxLayout="row" fxLayoutGap="10px" fxLayoutGap.lt-sm="0px">                       
                        <mat-form-field appearance="outline" fxFlex="50">
                            <mat-label>Current City</mat-label>
                            <input matInput placeholder="Current City*" formControlName="currentCity" maxlength="50">
                            <mat-error *ngIf="signupForm.get('currentAddress').get('currentCity').hasError('required')">
                                Please enter current City
                            </mat-error>
                        </mat-form-field>
                        <mat-form-field appearance="outline" subscriptSizing="dynamic">
                            <mat-label>State</mat-label>
                            <input type="text" placeholder="Enter your state" aria-label="State" matInput
                                [formControl]="stateCtrl" [matAutocomplete]="auto">
                                <mat-icon matSuffix *ngIf="stateCtrl.value" (click)="clearInput(stateCtrl)">close</mat-icon>

                            <mat-autocomplete #auto="matAutocomplete">
                                <mat-option *ngFor="let state of filteredStates | async" [value]="state">
                                    {{state}}
                                </mat-option>
                            </mat-autocomplete>
                            <mat-error *ngIf="isStateError" [@expandCollapse]="isStateError ? 'expanded' : 'collapsed'"
                                style="display: inline-block;">
                                Please select the state from the dropdown menu.
                            </mat-error>
                        </mat-form-field>


                    </div>
                    
                    <div fxLayout="column">
                        <div fxLayout="row" fxLayoutAlign="start center">
                            <mat-label>Willing to Relocate?</mat-label>
                        </div>
                        <div class="radio-group-container">
                            <mat-radio-group formControlName="willingToRelocate" aria-label="Select an option">
                                <mat-radio-button value=true>Yes</mat-radio-button>
                                <mat-radio-button value=false>No</mat-radio-button>
                            </mat-radio-group>
                        </div>
                                                           
                        
                        <div fxLayout="row" fxLayoutAlign="start center">
                            <mat-error *ngIf="signupForm.get('currentAddress').get('willingToRelocate').hasError('required') && signupForm.get('currentAddress').get('willingToRelocate').touched">
                                Please select an option.
                            </mat-error>
                        </div>
                    </div>

                </div> 
            </mat-step>
            <mat-step [stepControl]="signupForm.controls['visaInfo']" >

                <div formGroupName="termsSection">
                    <mat-form-field appearance="outline" subscriptSizing="dynamic" class="padding-top-1">
                      <mat-label>Confirmation Code</mat-label>
                      <input matInput formControlName="confirmationCode" placeholder="_____" maxlength="5" class="confirmation-code-input">
                      <mat-error *ngIf="signupForm.get('termsSection.confirmationCode').hasError('required')">
                        Confirmation Code is required.
                        <a href="javascript:void(0)" (click)="openConfirmationDialog()">Click here if you did not receive confirmation
                          code on your email. </a>
                      </mat-error>
                      <mat-error *ngIf="signupForm.get('termsSection.confirmationCode').hasError('minlength')">
                        Confirmation Code must be 5 characters long.
                      </mat-error>
                      <mat-error *ngIf="signupForm.get('termsSection.confirmationCode').hasError('maxlength')">
                        Confirmation Code must be 5 characters long.
                      </mat-error>
                      <mat-hint>
                        Please allow up to a few minutes to receive your confirmation code. If you have not received it after a few minutes, please refresh your inbox and check your spam folder.
                        <br /><a href="javascript:void(0)" (click)="openConfirmationDialog()">Click here if you have not received a confirmation code.</a>
                      </mat-hint>
                    </mat-form-field>
                  </div>
                  
                <div formGroupName="visaInfo" class="company-details">
                    <mat-card-title>Visa Info</mat-card-title>
                    <p *ngIf="!showVisaInfo"> As USA citizen you have no need for visa sponsorship.</p>
                    <div *ngIf="showVisaInfo">
                    <div fxLayout="column">
                        <div fxLayout="row" fxLayoutAlign="start center">
                            <mat-label>Do you now, or will you in the future, require immigration sponsorship for work authorization (for example, J-1 or H-2B status)?</mat-label>
                        </div>
                        <div class="radio-group-container">
                            <mat-radio-group formControlName="sponsorshipRequired" aria-label="Select an option">
                                <mat-radio-button value=true>Yes</mat-radio-button>
                                <mat-radio-button value=false>No</mat-radio-button>
                            </mat-radio-group>
                        </div>
                                                           
                        
                        <div fxLayout="row" fxLayoutAlign="start center">
                            <mat-error *ngIf="signupForm.get('visaInfo').get('sponsorshipRequired').hasError('required') && signupForm.get('visaInfo').get('sponsorshipRequired').touched">
                                Please select an option.
                            </mat-error>
                        </div>
                    </div>
                    <div fxLayout="column" *ngIf="signupForm.get('visaInfo.sponsorshipRequired').value === 'true'" [@fadeIn]="fadeIn">
                        <div fxLayout="row" fxLayoutAlign="start center">
                            <mat-label>If yes, which type of visa are you seeking?</mat-label>
                        </div>
                        <div class="radio-group-container">
                            <mat-radio-group formControlName="sponsorshipSeeking" aria-label="Select an option">
                                <mat-radio-button value="J1">J-1 </mat-radio-button>
                                <mat-radio-button value="H2B">H-2B </mat-radio-button>
                                <mat-radio-button value="Either">Open to either </mat-radio-button>
                            </mat-radio-group>
                        </div>
                                                           
                        
                        <div fxLayout="row" fxLayoutAlign="start center">
                            <mat-error *ngIf="signupForm.get('visaInfo').get('sponsorshipSeeking').hasError('required') && signupForm.get('visaInfo').get('sponsorshipSeeking').touched">
                                Please select an option.
                            </mat-error>
                        </div>
                    </div>


                        <div fxLayout="column">
                        <div fxLayout="row" fxLayoutAlign="start center">
                            <mat-label>Do you currently have an H-2B or J-1 visa?</mat-label>
                        </div>
                        <div class="radio-group-container">
                            <mat-radio-group formControlName="ownVisa" aria-label="Select an option">
                                <mat-radio-button value=true>Yes</mat-radio-button>
                                <mat-radio-button value=false>No</mat-radio-button>
                            </mat-radio-group>
                        </div>
                                                           
                        
                        <div fxLayout="row" fxLayoutAlign="start center">
                            <mat-error *ngIf="signupForm.get('visaInfo').get('ownVisa').hasError('required') && signupForm.get('visaInfo').get('ownVisa').touched">
                                Please select an option.
                            </mat-error>
                        </div>
                    </div>

                    <div fxLayout="column" *ngIf="signupForm.get('visaInfo.ownVisa').value === 'true'" [@fadeIn]="fadeIn">
                        <div fxLayout="row" fxLayoutAlign="start center">
                            <mat-label>If yes, which type of visa do you currently have?</mat-label>
                        </div>
                        <div class="radio-group-container">
                            <mat-radio-group formControlName="currentVisa" aria-label="Select an option">
                                <mat-radio-button value="J1">J-1 </mat-radio-button>
                                <mat-radio-button value="H2B">H-2B </mat-radio-button>
                            </mat-radio-group>
                        </div>
                                                           
                        
                        <div fxLayout="row" fxLayoutAlign="start center">
                            <mat-error *ngIf="signupForm.get('visaInfo').get('currentVisa').hasError('required') && signupForm.get('visaInfo').get('currentVisa').touched">
                                Please select an option.
                            </mat-error>
                        </div>
                        <div fxLayout="row" fxLayoutAlign="start center" [@fadeIn]="fadeIn">
                            <label>When does your current visa expire?</label>
                        </div>
                        <!-- Datepicker for current visa expiry date -->
                        <div fxLayout="row" fxLayoutAlign="start center" [@fadeIn]="fadeIn" class="padding-top-1">
                            <mat-form-field appearance="outline" >
                            <mat-label>When does your current visa expire?</mat-label>
                            <input matInput [matDatepicker]="picker1" formControlName="currentVisaExpiryDate"  [min]="minDate">
                            <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                            <mat-datepicker #picker1></mat-datepicker>
                            <mat-error *ngIf="signupForm.get('visaInfo').get('currentVisaExpiryDate').hasError('required') && signupForm.get('visaInfo').get('currentVisaExpiryDate').touched">
                                Please enter the date.
                            </mat-error>
                            </mat-form-field>
                        </div>
                        <div fxLayout="row" fxLayoutAlign="start center" *ngIf="signupForm.get('visaInfo.currentVisa').value === 'H2B'" [@fadeIn]="fadeIn">
                            <label>If you are currently on an H-2B visa, when do you hit your 3-year limit?</label>
                        </div>
                        <!-- Datepicker for H-2B 3-year limit -->
                        <div fxLayout="row" fxLayoutAlign="start center"  class="padding-top-1" *ngIf="signupForm.get('visaInfo.currentVisa').value === 'H2B'" [@fadeIn]="fadeIn">
                            <mat-form-field appearance="outline" >
                            <mat-label>Currently H-2B end date</mat-label>
                            <input matInput [matDatepicker]="picker2" formControlName="h2bThreeYearLimit" [min]="minDate">
                            <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                            <mat-datepicker #picker2></mat-datepicker>
                            <mat-error *ngIf="signupForm.get('visaInfo').get('h2bThreeYearLimit').hasError('required') && signupForm.get('visaInfo').get('h2bThreeYearLimit').touched">
                                Please enter the date.
                            </mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                </div>

                </div> 
                <div formGroupName="termsSection">
                    <mat-card class="my-card">
                        <mat-card-header>
                            <mat-card-title>Terms and Conditions</mat-card-title>
                        </mat-card-header>
                        <mat-card-content>
                            <div class="form-field">
                                <mat-checkbox color="accent" formControlName="termsOfService">
                                    I agree to the Terms of Service
                                </mat-checkbox>
                                <button type="button" (click)="openTermsOfService()" mat-icon-button
                                    aria-label="Terms of Service">
                                    <mat-icon>open_in_new</mat-icon>
                                </button>
                                <mat-error *ngIf="signupForm.get('termsSection').get('termsOfService').touched && 
                                        signupForm.get('termsSection').get('termsOfService').hasError('required')">
                                    You have to agree to the Terms of Service
                                </mat-error>
                            </div>
<!-- 
                            <div class="form-field">
                                <mat-checkbox color="accent" formControlName="privacyPolicy">
                                    I agree to the Privacy Policy
                                </mat-checkbox>
                                <button type="button" (click)="openPrivacyPolicy()" mat-icon-button
                                    aria-label="Privacy Policy">
                                    <mat-icon>open_in_new</mat-icon>
                                </button>
                                <mat-error *ngIf="signupForm.get('termsSection').get('privacyPolicy').touched && 
                                        signupForm.get('termsSection').get('privacyPolicy').hasError('required')">
                                    You have to agree to the Privacy Policy
                                </mat-error>
                            </div>
                           -->
                        </mat-card-content>
                    </mat-card>
                </div>
            </mat-step>
        </mat-horizontal-stepper>
            </mat-card-content>

            <mat-card-actions>
                <button *ngIf="stepper.selectedIndex !== 0" mat-button (click)="stepper.previous()" type="button">Back</button>
                <button *ngIf="stepper.selectedIndex === 0" mat-raised-button class="signup-button" color="primary" mat-button (click)="goToNextStep()"  type="button">Next</button>
                <button *ngIf="stepper.selectedIndex !== 0"  mat-raised-button class="signup-button" color="primary" type="submit">Sign Up</button>
            </mat-card-actions>
        </div>
    
    </mat-card>

</form>