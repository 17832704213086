import { Component } from '@angular/core';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { HttpClient } from '@angular/common/http';
import { Package } from 'src/app/models/ChargeOverModels';
import { AuthService, User } from '@auth0/auth0-angular';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '../../api.service';
import { SharedDataService } from '../../shared-data-service.service';
import { EMPTY, Observable, Subject, catchError, filter, from, map, startWith, switchMap, take, takeUntil } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { ErrorModalComponent } from '../../error-modal/error-modal.component';
import { EmployerContactSubscriptionDTO, ChangePlanRequest, Contact, ContactPermissions, ContactWithPermissionsDto } from 'src/app/models/AccountManagementModels';
import { InfoModalComponent } from 'src/app/info-modal/info-modal.component';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'app-sort-contacts',
  templateUrl: './sort-contacts.component.html',
  styleUrls: ['./sort-contacts.component.css']
})
export class SortContactsComponent {
  unsubscribe$ = new Subject<void>();
  contactsWithPermissions: ContactWithPermissionsDto[];
  isLoading = true;
  isLoadingOrder = true;
  employerContactSubscription: EmployerContactSubscriptionDTO;
  employerContactId: number;
  userType: string;
  portalURL: string;
  adminLimit: number;
  adminText: string;
  adminUrl: string;
  contactPermissions: ContactPermissions;

  constructor(private http: HttpClient,
    public auth: AuthService,
    private sharedDataService: SharedDataService,
    private apiService: ApiService,
    private route: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
    private sanitizer: DomSanitizer) {
  }

  ngOnInit(): void {
    this.isLoading = true;

    this.auth.user$
      .pipe(
        // Remove null or undefined values
        filter(user => !!user && !!user.nickname),
        // Get the primary employer for the contact
        switchMap(user => {
          const arrMeta = user.nickname.split(':');
          this.employerContactId = Number.parseInt(arrMeta[1]);
          this.userType = arrMeta[0];
          this.portalURL = this.sharedDataService.getData('portalURL');
          this.adminUrl = this.portalURL + "/Employers/EmployerDetail?a=Link&type=panContact"
          if (this.userType === 'E') {
            return this.apiService.getPrimaryEmployerForContact(this.employerContactId);
          }


          return EMPTY;
        }),

        // Handle errors in the pipeline
        catchError(e => {
          console.error(e);
          this.openErrorModal(e);
          this.router.navigate(['/error']);
          return EMPTY;
        }),

        // Unsubscribe when component is destroyed
        takeUntil(this.unsubscribe$)
      ).subscribe(employerContactSubscription => {
        this.employerContactSubscription = employerContactSubscription;
        const acceptTerms = this.employerContactSubscription.employer.acceptTerms;
        const acceptSubscription = this.employerContactSubscription.employer.acceptSubscription;
        // Check if either date is null or older than the SC open date
        if ((acceptTerms === null || new Date(acceptTerms) < new Date('2021-01-01')) ||
          (acceptSubscription === null || new Date(acceptSubscription) < new Date('2021-01-01'))) {
          this.router.navigate(['/accept-terms']);
          return;
        }

        this.apiService.IsFeatureLimited(this.employerContactSubscription.employer.employerId, "n_user_login")
          .pipe(
            catchError(e => {
              console.error(e);
              this.openErrorModal(e);
              this.isLoading = false;
              //this.router.navigate(['/error']);
              return EMPTY;
            }),
            takeUntil(this.unsubscribe$)
          )
          .subscribe(data => {
            this.adminLimit = data;
            this.isLoading = false;
          });
        this.getContacts();
      });

  }

  getContacts() {
    this.isLoadingOrder = true;
    this.apiService.getContacts(this.employerContactSubscription.employer.employerId)
      .pipe(
        catchError(e => {
          console.error(e);
          this.openErrorModal(e);
          this.isLoadingOrder = false;
          //this.router.navigate(['/error']);
          return EMPTY;
        }),
        takeUntil(this.unsubscribe$)
      )
      .subscribe(data => {
        this.contactsWithPermissions = data;
        this.isLoadingOrder = false;
      });
  }



  submit() {
    // Ensure proper plan check if needed (adjust as required)
    if (this.employerContactSubscription.plan.planId < 3) {
      // Add any additional logic if necessary
    }
    
    this.isLoadingOrder = true;
    
    // Map the DTO array to extract only the contact objects
    const contactsToUpdate = this.contactsWithPermissions.map(item => item.contact);
  
    this.apiService.updateContacts(contactsToUpdate)
      .pipe(
        catchError(e => {
          console.error(e);
          this.openErrorModal(e);
          this.isLoadingOrder = false;
          // Optionally navigate to an error page here
          return EMPTY;
        }),
        takeUntil(this.unsubscribe$)
      )
      .subscribe(data => {
        this.isLoadingOrder = false;
        this.getContacts();
      });
  }
  

  drop(event: CdkDragDrop<ContactWithPermissionsDto[]>) {
    // Create a shallow copy of the current array
    const newOrder = [...this.contactsWithPermissions];
    
    // Simulate the move in the temporary array
    moveItemInArray(newOrder, event.previousIndex, event.currentIndex);
  
    // Check if the first contact in the new order is an admin.
    // If not, show the modal and cancel the drop.
    if (!newOrder[0].contactPermission.employerAdmin) {
      this.openInfoModal(
        "An Admin is required to be in the first spot. To change a user's account type click the \"Open\" button below.",
        this.adminUrl
      );
      return;
    }
    
    // If the validation passes, update the contacts array.
    this.contactsWithPermissions = newOrder;
    
    // Update SortOrderAdmin values on the underlying contact objects.
    this.contactsWithPermissions.forEach((item, index) => {
      item.contact.sortOrderAdmin = index + 101;
    });
  }
  
  

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  getRole(role: boolean): string {
    switch (role) {
      case true:
        return 'Admin';
      case false:
        return 'User';
      default:
        return 'Other';
    }
  }

  openErrorModal(error: any): void {
    const dialogRef = this.dialog.open(ErrorModalComponent, {
      data: error,
      maxWidth: '70vw', // specify max width
      maxHeight: '80vh', // specify max height
      //height: '80vh' // specify height
    });

    // Optionally, you can subscribe to the afterClosed observable to perform actions after the modal is closed.
    dialogRef.afterClosed().subscribe(() => {
      // Perform any actions after the modal is closed (if needed).
    });
  }
  openInfoModal(message: string, url?: string): void {
    const dataObject = { message: message };

    if (url) {
      dataObject['url'] = url;
    }

    const dialogRef = this.dialog.open(InfoModalComponent, {
      data: dataObject,
      maxWidth: '70vw',
      maxHeight: '80vh',
    });

    dialogRef.afterClosed().subscribe(() => {
      // Handle the modal close event if needed
    });
  }

}
