import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HousingImagesDTO } from 'src/app/models/JobModels';

@Component({
  selector: 'app-image-modal',
  template: `
    <div class="image-modal">
      <img [src]="data.imageUrl" [alt]="data.photoCaption || 'Image'" />
      <p>{{ data.photoCaption }}</p>
    </div>
  `,
  styles: [
    `
      .image-modal {
        text-align: center;
      }
      .image-modal img {
        max-width: 100%;
        height: auto;
        border-radius: 4px;
        margin-bottom: 10px;
      }
      .image-modal p {
        font-size: 16px;
        color: #555;
      }
    `
  ]
})
export class ImageModalComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: HousingImagesDTO) {}
}
