import { Component, OnInit } from '@angular/core';
import { AuthService, User } from '@auth0/auth0-angular';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { TermsOfServiceComponent } from '../../terms-of-service/terms-of-service.component';
import { PlatformSubscriptionAgreementComponent } from '../../platform-subscription-agreement/platform-subscription-agreement.component';
import { EMPTY, Observable, Subject, catchError, concatMap, filter, finalize, forkJoin, from, map, of, startWith, switchMap, take, takeUntil } from 'rxjs';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { ApiService } from 'src/app/api.service';
import { AcceptTermsModel, CreateEmployerModel, Employer } from '../../models/SignupModels';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { AuthHttpInterceptor } from '@auth0/auth0-angular';
import { SharedDataService } from '../../shared-data-service.service';
import { ErrorModalComponent } from '../../error-modal/error-modal.component';
import { EmployerContactSubscriptionDTO } from 'src/app/models/AccountManagementModels';



@Component({
  selector: 'app-accept-terms',
  templateUrl: './accept-terms.component.html',
  styleUrls: ['./accept-terms.component.css'],
  animations: [
    trigger('expandCollapse', [
      state('collapsed', style({ height: '0px', minHeight: '0', marginBottom: '0' })),
      state('expanded', style({ height: '*', marginBottom: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4,0.0,0.2,1)')),
    ])
  ],
})
export class AcceptTermsComponent implements OnInit {
  unsubscribe$ = new Subject<void>();
  signupForm: FormGroup;
  yearly = false;
  isLoading = false;
  user: User;
  userType: string;
  portalURL: string;
  employerContactSubscription: EmployerContactSubscriptionDTO;

  constructor(private formBuilder: FormBuilder,
    public auth: AuthService,
    public dialog: MatDialog,
    private api: ApiService,
    private router: Router,
    private sharedDataService: SharedDataService,
    private route: ActivatedRoute) {

      this.signupForm = this.formBuilder.group({
        section2: this.formBuilder.group({
          companyName: [''],
          firstName: [''],
          lastName: [''],
          city: [''],
        }),
        section3: this.formBuilder.group({
          termsOfService: [false, Validators.requiredTrue],
          platformSubscriptionAgreement: [false, Validators.requiredTrue],
        }),
      });
    }
  ngOnInit(): void {
    this.auth.user$
      .pipe(
        // Remove null or undefined values
        filter(user => !!user && !!user.nickname),

        // Get the primary employer for the contact
        switchMap(user => {
          const arrMeta = user.nickname.split(':');
          const employerContactId = Number.parseInt(arrMeta[1]);
          this.userType = arrMeta[0];
          this.portalURL = this.sharedDataService.getData('portalURL');
          if (this.userType === 'E') {
            return this.api.getPrimaryEmployerForContact(employerContactId);
          }
          return EMPTY;
        }),

        // Handle errors in the pipeline
        catchError(e => {
          console.error(e);
          this.openErrorModal(e);
          this.router.navigate(['/error']);
          return EMPTY;
        }),

        // Unsubscribe when component is destroyed
        takeUntil(this.unsubscribe$)
      ).subscribe(employerContactSubscription => {
        this.employerContactSubscription = employerContactSubscription;
        this.signupForm.get('section2').get('companyName').setValue(this.employerContactSubscription.employer.legalBusinessName);
        this.signupForm.get('section2').get('firstName').setValue(this.employerContactSubscription.contact.contactFirstName);
        this.signupForm.get('section2').get('lastName').setValue(this.employerContactSubscription.contact.contactLastName);
        this.signupForm.get('section2').get('city').setValue(this.employerContactSubscription.employer.city);
      });
  }

  onSubmit(): void {
    if (this.signupForm.invalid) {
      this.signupForm.markAllAsTouched(); // mark fields as touched to show errors
    } else {
      const acceptTermsModel: AcceptTermsModel = {
        termsOfService: this.signupForm.get('section3').get('termsOfService').value,
        platformSubscriptionAgreement: this.signupForm.get('section3').get('platformSubscriptionAgreement').value,
        employerContactId: this.employerContactSubscription.contact.employerContactId,
        employerId: this.employerContactSubscription.employer.employerId
      };
      this.isLoading = true;
      this.api.acceptTerms(acceptTermsModel).pipe(
        finalize(() => this.isLoading = false)
      ).subscribe({
        next: (response) => {
          this.router.navigate(['/']);
        },
        error: (error) => {
          this.openErrorModal(error);
        }
      });
    }
  }

  openTermsOfService(): void {
    const dialogRef = this.dialog.open(TermsOfServiceComponent, {
      maxWidth: '70vw',  // specify max width
      maxHeight: '80vh',  // specify max height
      height: '80vh',  // specify height
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        // Set the 'termsOfService' form control value to true
        this.signupForm.get('section3').get('termsOfService').setValue(true);
      }
    });
  }
  openPlatformSubscriptionAgreement(): void {
 
    // Subscribe to the Observable to get the state name
    this.api.GetStateName(Number.parseInt(this.employerContactSubscription.employer.state)).subscribe(stateName => {
      // Open the dialog inside the subscription callback
      const dialogRef = this.dialog.open(PlatformSubscriptionAgreementComponent, {
        data: {
          signupForm: this.signupForm,
          stateCtrl: stateName.content
        },
        maxWidth: '70vw',
        maxHeight: '80vh',
        height: '80vh',
      });
  
      // Handle the dialog close event
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          // Set the 'termsOfService' form control value to true
          this.signupForm.get('section3').get('platformSubscriptionAgreement').setValue(true);
        }
      });
    });
  }
  
  openErrorModal(error: any): void {
    const dialogRef = this.dialog.open(ErrorModalComponent, {
      data: error,
      maxWidth: '70vw', // specify max width
      maxHeight: '80vh', // specify max height
      //height: '80vh' // specify height
    });

    // Optionally, you can subscribe to the afterClosed observable to perform actions after the modal is closed.
    dialogRef.afterClosed().subscribe(() => {
      // Perform any actions after the modal is closed (if needed).
    });
  }
}
