import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SharedDataService {

  setData<T>(key: string, data: T): void {
    // Save data to the class variable as well as localStorage
    localStorage.setItem(key, JSON.stringify(data));
  }

  getData<T>(key: string): T | null {
    const storedData = localStorage.getItem(key);
    
    if (storedData) {
      try {
        // Parse stored data from string to its original form
        return JSON.parse(storedData) as T;
      } catch (error) {
        console.error('Error parsing stored data from localStorage', error);
        return null;
      }
    }

    return null;
  }

  clearStorage(): void {
    localStorage.clear();
  }
}