
  <div class="slider-container">
    <carousel [interval]="5000" [noPause]="false" [noWrap]="false">
      <slide *ngFor="let img of slides; let i = index">
        <div class="carousel-caption"></div>
        <div class="picsum-img-wrapper">
          <img [src]="img" alt="alt" class="carousel-image" />
        </div>
      </slide>
    </carousel>
  </div>
  

<!-- Page Info (below the carousel, or overlay if you prefer) -->
<div class="page-info">
  <div class="info-wrapper" fxLayout="row" fxLayoutAlign="start center">

    <!-- Logo / Avatar -->
    <div class="logo-column">
      <div *ngIf="avatarUrl" class="user-avatar">
        <img [src]="avatarUrl" alt="Company Logo" />
      </div>
    </div>

    <!-- Title / Tagline -->
    <div class="title-column" fxLayout="column" fxLayoutAlign="center start">
      <h1 class="employer-title">{{ employerName }}</h1>
      <span class="did-you-know">{{ tagline }}</span>
    </div>

  </div>
</div>
