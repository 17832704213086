import { Component } from '@angular/core';
import { AuthService, User } from '@auth0/auth0-angular';
import { ApiService } from 'src/app/api.service';
import { Network } from 'src/app/models/SignupModels';
import { SharedDataService } from 'src/app/shared-data-service.service';
import { trigger, transition, useAnimation } from '@angular/animations';
import { bounceIn, fadeIn } from 'ng-animate';
import { catchError, EMPTY, filter, Subject, switchMap, takeUntil } from 'rxjs';



@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css'],
  animations: [trigger('bounceIn', [transition('* => *', useAnimation(bounceIn))]), trigger('fadeIn', [transition('* => *', useAnimation(fadeIn))])],

})
export class FooterComponent {
  unsubscribe$ = new Subject<void>();
  portalURL: string;
  networks: Network[];
  showRhaDisclaimer = false;
  user: User;


  bounceIn: any;
  fadeIn: any;
  constructor(
    public auth: AuthService,
    private apiService: ApiService,
    private sharedDataService: SharedDataService,
  ) {

  }

  ngOnInit() {
    this.portalURL = this.sharedDataService.getData('portalURL');
    if (!this.portalURL) {
      this.apiService.GetConfigurationParam("PortalURL").subscribe(res => {
        this.portalURL = res;
        this.sharedDataService.setData<string>('portalURL', res);
      });
    }
    // Check if user is authenticated
    // this.auth.isAuthenticated$.subscribe(isAuthenticated => {
    //   if(isAuthenticated){
      this.auth.user$
        .pipe(
          // Remove null or undefined values
          filter(user => !!user && !!user.nickname),

          // Get the primary employer for the contact
          switchMap(user => {
            const arrMeta = user.nickname.split(':');
            const employerContactId = Number.parseInt(arrMeta[1]);
            let userType = arrMeta[0];
            this.portalURL = this.sharedDataService.getData('portalURL');
            if (userType === 'E') {
              // Only call getMemberNetworks if the user is authenticated
              this.apiService.getMemberNetworks().subscribe(
                (networks: Network[]) => {
                  this.networks = networks;

                  this.showRhaDisclaimer = this.networks.some(network =>
                    network.networkName === 'RHA' || network.networkName === 'Resort Hotel Association'
                  );
                },
                (error) => {
                  console.error('Error fetching networks:', error);
                }
              );
            }
            return EMPTY;
          }),

          // Handle errors in the pipeline
          catchError(e => {
            console.error(e);

            return EMPTY;
          }),
  
          // Unsubscribe when component is destroyed
          takeUntil(this.unsubscribe$)
        ).subscribe()
    // }})
  }
}

