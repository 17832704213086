import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-info-modal',
  templateUrl: './info-modal.component.html',
  styleUrls: ['./info-modal.component.css']
})
export class InfoModalComponent {
  message: string;
  url: string | null = null; 
  title: string | null = null; 


  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<InfoModalComponent>) {
    this.message = data.message;
    this.url = data.url || null; 
    this.title = data.title || null;
  }

  closeModal() {
    this.dialogRef.close()
  }
}
