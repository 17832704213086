<div class="container" *ngIf="isLoading">
  <app-loading></app-loading>
</div>
<form *ngIf="!isLoading" [formGroup]="paymentForm">
  <mat-card class="">
    <div class="signup-payment-details-mat-card">
      <h2 class="center-title">Payment Details</h2>

      <mat-card-content>
        <!-- Section 1: Company Details -->
        <mat-card-title class="center-title"><strong>Company Details</strong></mat-card-title>
        <div>
          <p>
            <span>{{employerData?.companyName}}</span><br>
            <span>{{employerData?.firstName}} {{employerData?.lastName}}</span><br>
            <span>{{employerData?.email}} </span><br>
            <span>{{employerData?.street}}</span><br>
            <span>{{employerData?.city}} {{employerData?.zip}}</span><br>
            <span>{{employerData?.state}}</span><br>
            <span *ngIf="networkName">Member of {{networkName}}</span><br>
          </p>
        </div>

        <!-- Confirmation Code -->
        <mat-form-field appearance="outline" subscriptSizing="dynamic">
          <mat-label>Confirmation Code</mat-label>
          <input matInput formControlName="confirmationCode" placeholder="Check your email for Confirmation Code">
          <mat-error *ngIf="paymentForm.get('confirmationCode').errors?.required">
            Confirmation Code is required. <a href="javascript:void(0)" (click)="openConfirmationDialog()">Did not receive confirmation
              code?</a>
          </mat-error>
          <mat-hint><a href="javascript:void(0)" (click)="openConfirmationDialog()">Did not receive confirmation
              code?</a>
          </mat-hint>
        </mat-form-field>

        <!-- Credit Card Details -->
        <div *ngIf="isCreditCardFieldVisible">
          <mat-form-field appearance="outline">
            <mat-label>Name on Card</mat-label>
            <input matInput formControlName="creditCardName" placeholder="Name on Card">
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>Card Number</mat-label>
            <input matInput formControlName="cardNumber" placeholder="Credit Card Number">
            <mat-error *ngIf="paymentForm.get('cardNumber').errors?.required">
              Card Number is required.
            </mat-error>
            <mat-error *ngIf="paymentForm.get('cardNumber').hasError('invalidCard')">
              Card Number is not valid.
            </mat-error>
          </mat-form-field>
          <div fxLayout="row" fxLayoutGap="10px">
            <mat-form-field appearance="outline" fxFlex="40">
              <mat-label>Exp. Month</mat-label>
              <mat-select formControlName="expMonth" placeholder="Exp. Month">
                <mat-option *ngFor="let month of months" [value]="month">{{month}}</mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field appearance="outline" fxFlex="40">
              <mat-label>Exp.Year</mat-label>
              <mat-select formControlName="expYear" placeholder="Exp. Year">
                <mat-option *ngFor="let year of years" [value]="year">{{year}}</mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field appearance="outline" fxFlex="20">
              <mat-label>CVV</mat-label>
              <input matInput formControlName="cvv" placeholder="CVV">
              <mat-error *ngIf="paymentForm.get('cvv').errors?.required">
                CVV is required.
              </mat-error>
              <mat-error *ngIf="paymentForm.get('cvv').errors?.pattern">
                CVV must be 3 or 4 digits.
              </mat-error>
            </mat-form-field>
          </div>
          <mat-error *ngIf="paymentForm.controls.expMonth.hasError('expired')">Your card has expired.</mat-error>
          <div fxLayout="row" fxLayoutGap="10px" class="credit-card-logos">
            <img src="assets/visa.png" alt="Visa" class="credit-card-logo">
            <img src="assets/mastercard.png" alt="MasterCard" class="credit-card-logo">
            <img src="assets/amex.png" alt="American Express" class="credit-card-logo">
            <img src="assets/discover.png" alt="Discover" class="credit-card-logo">
          </div>
          <mat-card-subtitle> Unable to pay via credit card? Contact us at <a href="mailto:accounting@seasonalconnect.com">accounting&#64;seasonalconnect.com</a> or (617)716-6491 to connect with our team.</mat-card-subtitle>
         <br/>
        </div>


        <!-- Section 4: Plan Selection -->
        <mat-card-title>Plan Selection</mat-card-title>

        <div class="toggle-button">
          <span>Monthly </span>
          <mat-slide-toggle formControlName="yearly" color="accent"
            (change)="yearlyToggleTriggered()"></mat-slide-toggle>
          <span> Yearly </span>
        </div>
        <div fxLayout="row" fxLayoutGap="10px" fxLayoutGap.lt-sm="0px">
          <mat-form-field appearance="outline">
            <mat-label>Plan</mat-label>
            <mat-select formControlName="plan" (selectionChange)="planChangeTrigered()">
              <mat-option *ngFor="let plan of plans" [value]="plan.value">
                {{plan.viewValue}} <!-- - ${{plan.price}} -->
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <!-- Amount and Coupon -->
        <div fxLayout="row" fxLayoutGap="10px">
          <mat-form-field appearance="outline" fxFlex="75">
            <mat-label>Coupon Code</mat-label>
            <input matInput formControlName="couponCode" placeholder="Coupon Code" (keydown.enter)="calculatePrice()">
          </mat-form-field>
          <button mat-raised-button type="button" class="apply-coupon-button" fxFlex="25"
            (click)="calculatePrice()">Apply</button>
        </div>
        <div fxLayout="row" fxLayoutGap="10px">
          <strong>Base Price:</strong>
          <div *ngIf="!isPriceLoading">{{ planPriceResponse.basePrice | currency:'USD':'symbol':'.2-2' }}</div>
          <mat-spinner *ngIf="isPriceLoading" [diameter]="15"></mat-spinner>
        </div>

        <div fxLayout="row" fxLayoutGap="10px">
          <strong>Discount:</strong>
          <div *ngIf="!isPriceLoading">{{ planPriceResponse.discount | currency:'USD':'symbol':'.2-2' }}</div>
          <mat-spinner *ngIf="isPriceLoading" [diameter]="15"></mat-spinner>
        </div>
        <div fxLayout="row" fxLayoutGap="10px">
          <strong>Amount to Pay:</strong>
          <div *ngIf="!isPriceLoading">{{ amountToPay | currency:'USD':'symbol':'.2-2' }}</div>
          <mat-spinner *ngIf="isPriceLoading" [diameter]="15"></mat-spinner>
        </div>
        <br>
      </mat-card-content>
      <mat-card-actions>
        <button mat-raised-button class="submit-button" color="primary" [disabled]="paymentForm.invalid"
          (click)="onSubmit()">Submit</button>
      </mat-card-actions>
    </div>
  </mat-card>
</form>